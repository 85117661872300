import { useEffect, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { EditUserModalProps } from "./EditUserModalProps";
import Status from "../../../enums/Status";
import IAccount from "../../../models/IAccount";

export default function EditUserModal({
    show,
    onHide,
    onSave,
    currentUser,
}: EditUserModalProps) {
    const [editableUser, setEditableUser] = useState<IAccount | null>(null);
    const [status, setStatus] = useState<Status>(Status.NEUTRAL);

    useEffect(() => {
        setEditableUser(currentUser);
    }, [currentUser]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (editableUser) {
            const { name, value } = e.target;
            setEditableUser({ ...editableUser, [name]: value });
        }
    };

    const handleSave = () => {
        if (editableUser) {
            onSave(editableUser);
            setStatus(Status.LOAD_SUCCESS);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{editableUser ? "Edit User" : "Delete User"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {editableUser && (
                    <Form>
                        <Form.Group controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                value={editableUser.username}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={editableUser.firstName}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="lastName"
                                value={editableUser.lastName}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Form>
                )}
                {status === Status.LOADING && (
                    <Alert variant="info">Loading...</Alert>
                )}

                {status === Status.LOAD_SUCCESS && (
                    <Alert variant="success">User updated successfully.</Alert>
                )}

                {status === Status.LOAD_ERROR && (
                    <Alert variant="danger">Failed to update user.</Alert>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}