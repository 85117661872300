import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface ILink {
    title: string;
    url: string;
}

interface IGuideSectionProps {
    section: string;
}

const GuideSection = (props: IGuideSectionProps) => {
    const { t } = useTranslation();
    const tPrefix = `helpdesk.form.guide.${props.section}`;

    const pargraphs = t(`${tPrefix}.paragraphs`, { returnObjects: true });
    const links = t(`${tPrefix}.links`, { returnObjects: true }) as ILink[];

    return (
        <>
            <h3>{t(`${tPrefix}.title`)}</h3>
            {
                pargraphs && Array.isArray(pargraphs) &&
                pargraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>)
            }
            {
                links && Array.isArray(links) &&
                <ul>
                {
                    links.map((link, index) => (
                        <li key={index}>
                            <a href={link.url} target="_blank" rel="noreferrer">{link.title}</a>
                        </li>
                    ))
                }
            </ul>
            }
        </>
    );
}

const NewTicketGuidance = () => (
    <Container fluid>
        <Row>
            <Col>
                <GuideSection section="howToSubmit" />
                <GuideSection section="logForSomeoneElse" />
                <GuideSection section="screenshots" />
                <GuideSection section="videos" />
                <GuideSection section="howToRecord" />
            </Col>
        </Row>
    </Container>
);

export default NewTicketGuidance;