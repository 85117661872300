import Tickets from "./Tickets";
import { useLocation, useNavigate } from "react-router-dom";
import Ticket from "./ticket/Ticket";

export default function Helpdesk() {
    const navigate = useNavigate();
    const location = useLocation();

    if (location.pathname.startsWith("/helpdesk/tickets")) {
        return <Tickets />;
    }
    
    if (location.pathname.startsWith("/helpdesk/ticket")) {
        const ticketId = Number(location.pathname.split("/").pop());
        return <Ticket ticketId={ticketId} />;
    }

    navigate("/");
    return null;
}