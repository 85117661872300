import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ITicket from "../models/ITicket";
import Status from "../enums/Status";
import TicketsCard from "./tickets/TicketsCard";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import StatCard from "../components/cards/statCard/StatCard";
import { useTranslation } from "react-i18next";
import accountService from "../services/AccountService";
import PageHeader from "../components/pageHeader/PageHeader";
import { useLocation } from "react-router-dom";
import ticketService from "../services/helpdesk/TicketService";
import TicketStatus from "../enums/TicketStatus";

const SelectedTicketStatus = (path: string) => {
    switch (path) {
        case "/helpdesk/tickets/onhold":
            return TicketStatus.ON_HOLD;
        case "/helpdesk/tickets/closed/month":
            return TicketStatus.CLOSED_IN_MONTH;
        case "/helpdesk/tickets/closed":
            return TicketStatus.CLOSED;
        case "/helpdesk/tickets/archived":
            return TicketStatus.ARCHIVED;
        case "/helpdesk/tickets/open":
        case "/helpdesk/tickets/open/my":
        case "/helpdesk/tickets/open/powerbi":
        default:
            return TicketStatus.OPEN;
    }
}

const Title = () => {
    const { t } = useTranslation();
    const location = useLocation();

    let title;

    switch (location.pathname) {
        case "/helpdesk/tickets/open":
            title = t("navigation.helpdesk.tickets.open");
            break;
        case "/helpdesk/tickets/open/my":
            title = t("navigation.helpdesk.tickets.my");
            break;
        case "/helpdesk/tickets/open/queue":
            title = t("navigation.helpdesk.tickets.queue");
            break;
        case "/helpdesk/tickets/open/powerbi":
            title = t("navigation.helpdesk.tickets.pbi");
            break;
        case "/helpdesk/tickets/onhold":
            title = t("navigation.helpdesk.tickets.hold");
            break;
        case "/helpdesk/tickets/closed/month":
            title = t("navigation.helpdesk.tickets.closedInMonth");
            break;
        case "/helpdesk/tickets/closed":
            title = t("navigation.helpdesk.tickets.closed");
            break;
        case "/helpdesk/tickets/archived":
            title = t("navigation.helpdesk.tickets.archived");
            break;
        default:
            title = t("navigation.helpdesk.tickets.name");
            break;
    }

    return <PageHeader title={title} subtitle={t("navigation.helpdesk.name")} />
}

const DolphinStatCards = (props: { tickets: ITicket[] }) => {
    const { t } = useTranslation();

    const tickets = props.tickets;
    const total = tickets.length;
    const unassignedTotal = tickets.filter(t => !t.assignee).length;
    const severeTotal = tickets.filter(t => t.severity === 3).length;

    return (
        <Container fluid className="px-1">
            <Row>
                <Col xs={12} md={6} lg={4}>
                    <StatCard title={t("helpdesk.stats.total")} value={total} icon="List" />
                </Col>
                <Col lg={4} className="d-none d-lg-block">
                    <StatCard title={t("helpdesk.stats.awaitingTriage")} value={unassignedTotal} icon="Hourglass" />
                </Col>
                <Col lg={4} className="d-none d-lg-block">
                    <StatCard title={t("helpdesk.stats.severe")} value={severeTotal} icon="ExclamationTriangle" />
                </Col>
            </Row>
        </Container>
    );
}

export default function Tickets() {
    const { t } = useTranslation();
    
    const location = useLocation();
    const path = location.pathname;

    const [tickets, setTickets] = useState<ITicket[]>([]);
    const [status, setStatus] = useState<Status>(Status.LOADING);

    useEffect(() => getData(path, setTickets, setStatus), [path]);

    if (status === Status.LOADING) {
        return (
            <>
                <Title />
                <div className="text-center">
                    <Spinner animation="border" variant="dark" />
                </div>
            </>
        );
    }

    if (status === Status.LOAD_ERROR) {
        return (
            <>
                <Title />
                <Alert variant="danger">{t("helpdesk.exceptions.loadTickets")}</Alert>
            </>
        );
    }

    return (
        <>
            <Title />

                <DolphinStatCards tickets={tickets} />
                
            <TicketsCard tickets={tickets} />
        </>
    );
}

function getData(
    path: string,
    setTickets: Dispatch<SetStateAction<ITicket[]>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);
    
    try {
        const ticketStatus = SelectedTicketStatus(path);

        (async () => {
            const account = await accountService.getAccount();
            const roles = accountService.getRoles();

            let tickets = await ticketService.getTickets(ticketStatus);
            tickets = tickets.filter(t => {
                if (path.endsWith("/open/my")) {
                    const assignee = t.assignee?.emailAddress.toLowerCase();

                    if (roles.includes("Helpdesk") && !roles.includes("Administrator")) {
                        return false;
                    } else if (assignee === "support@fm24systems.com") {
                        const name = `${account.firstName} ${account.lastName}`.toLowerCase();
                        return t.assignee.name === name;
                    } else {
                        return assignee === account.username.toLowerCase();
                    }
                } else if (path.endsWith("/open/queue")) {
                    return !t.assignee;
                } else if (path.endsWith("/open/powerbi")) {
                    return t.systemOrProduct === "Power BI";
                } else {
                    return true;   
                }
            });

            setTickets(tickets);
            
            setStatus(Status.LOAD_SUCCESS);
        })();
    } catch {
        setStatus(Status.LOAD_ERROR)
    }
}