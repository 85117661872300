import { useLocation } from "react-router-dom";
import IRouteComponent from "../interfaces/IRouteComponent";

const components: IRouteComponent = {
    "/projects/open": <h1>Open</h1>,
    "/projects/complete": <h1>Closed</h1>
};

export default function Tickets() {
    const location = useLocation();
    return components[location.pathname];
}