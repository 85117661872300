import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import styles from './NewAttachmentForm.module.scss';
import Status from '../../../../enums/Status';
import { useTranslation } from 'react-i18next';
import { Col, ListGroup, ListGroupItem, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import ticketService from '../../../../services/helpdesk/TicketService';
import { Icon } from '../../../../components/icon/Icon';

interface INewAttachmentFormProps {
    ticketId: number;
    reloadAttachments: () => void;
}

interface IUpload {
    file: File;
    status: Status;
}

interface IUploadProps {
    upload: IUpload;
    ticketId: number;
}

const Upload = (props: IUploadProps) => {
    const { t } = useTranslation();

    const [upload, setUpload] = useState<IUpload>(props.upload);

    useEffect(() => uploadAttachment(props.ticketId, upload, setUpload), [props.ticketId, upload]);

    const errorTooltip = <Tooltip>{t("helpdesk.exceptions.uploadAttachment")}</Tooltip>;

    let size: string;

    if (upload.file.size < 1_024 || upload.file.size > 1_073_741_824) {
        size = `${upload.file.size} Bytes`;
    } else if (upload.file.size < 1_048_576) {
        size = `${(upload.file.size / 1024).toFixed(2)} KB`;
    } else {
        size = `${(upload.file.size / 1_048_576).toFixed(2)} MB`;
    }

    return (
        <ListGroupItem>
            <Row className="align-items-center">
                <Col>
                    <h4 className="mb-1 name">
                        {upload.file.name}
                    </h4>
                    <p className="card-text small text-body-secondary mb-1">
                        {size}
                    </p>
                </Col>
                <Col xs="auto">
                    {
                        (
                            upload.status === Status.SAVING &&
                            <Spinner size="sm" animation="border" variant="dark" />
                        ) ||
                        (
                            upload.status === Status.SAVE_SUCCESS &&
                            <span className="text-success">
                                <Icon iconName="CheckCircleFill" />
                            </span>
                        ) ||
                        (
                            upload.status === Status.SAVE_ERROR &&
                            <OverlayTrigger placement="left" overlay={errorTooltip}>
                                <span className="text-danger">
                                    <Icon iconName="ExclamationTriangleFill" />
                                </span>
                            </OverlayTrigger>
                        )
                    }
                </Col>
            </Row>
        </ListGroupItem>
    );
};

export default function NewAttachmentForm(props: INewAttachmentFormProps) {
    const { t } = useTranslation();

    const [uploads, setUploads] = useState<IUpload[]>([]);

    const handleUpload = (files: File[]) => setUploads(files.map(file => ({ file, status: Status.SAVING })));

    const onDrop = useCallback(handleUpload, []);
      
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    if (uploads.length !== 0 &&
        uploads.length === uploads.filter(u => u.status === Status.SAVE_SUCCESS).length) {
        props.reloadAttachments();
        setUploads([]);

        return <div />;
    }

    const dragStatusTextKey = isDragActive ? "dragActive" : "dragInactive";
    const text = t(`helpdesk.attachments.dropzone.${dragStatusTextKey}`);

    return (
        <div className="d-block">
            <form className={`${styles.dropzone} ${isDragActive ? styles.dragActive : ""}`}>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className="mb-0">{text}</p>
                </div>
            </form>

            <ListGroup variant='flush'>
                {uploads.map((upload, index) => <Upload key={index} ticketId={props.ticketId} upload={upload} />)}
            </ListGroup>
        </div>
    );
}

function uploadAttachment(
    ticketId: number,
    upload: IUpload,
    setUpload: Dispatch<SetStateAction<IUpload>>
) {
    try {
        ticketService.uploadAttachment(ticketId, upload.file)
            .then(result => upload.status = result ? Status.SAVE_SUCCESS : Status.SAVE_ERROR)
            .catch(() => upload.status = Status.SAVE_ERROR)
            .finally(() => setUpload(upload));
    } catch {
        upload.status = Status.SAVE_ERROR;
        setUpload(upload);
    }
}

