import { useState } from 'react';
import Cookies from 'js-cookie';
import IAuthenticationToken from '../models/IAuthenticationToken';
import { jwtDecode } from 'jwt-decode';

export function useAuth() {
    const [token, setToken] = useState(Cookies.get('token'));

    /**
     * @description Stores the authentication token in the user's cookies.
     * @param {string} token The authentication token to be stored
     */
    async function login(token: IAuthenticationToken) {
        await setTokenAsync(token);
        setToken(token.access_token);
    }

    /**
     * @description Removes the authentication token cookie and sets the token state to undefined.
     */
    async function logout() {
        await setTokenAsync(undefined);
        setToken(undefined);
    }

    /**
     * @description Sets the token in a cookie and returns a Promise that resolves with the token.
     * @param {string} token The token to set in the cookie.
     * @returns A Promise that resolves with the token.
     */
    const setTokenAsync = (token: IAuthenticationToken | undefined) => {
        return new Promise((resolve) => {
            if (token) {
                Cookies.set('token', token.access_token);
                Cookies.set('refreshToken', token.refresh_token, { expires: 7 });
            } else {
                Cookies.remove('token');
                Cookies.remove('refreshToken');
            }

            resolve(token);
        });
    };

    return { token, login, logout };
}