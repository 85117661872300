import axios from "axios";
import Cookies from "js-cookie";
import INetworkResponse from "../models/INetworkResponse";
import IErrorResponse from "../models/IErrorResponse";
import IAuthenticationToken from "../models/IAuthenticationToken";

/**
 * @description Handles all network requests
 */
class NetworkService {

    private api: any;

    constructor() {
        const baseUrl = process.env.REACT_APP_API_BASE_URL || "/api/";
        this.api = axios.create({
            baseURL: baseUrl,
            timeout: 30_000
        });

        // Intercept requests and add auth token to headers
        this.api.interceptors.request.use(async (config: any) => {
            const token = Cookies.get('token');
            
            // If auth token cookie is stored, add it to request headers
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }

            return config;
        }, (error: any) => Promise.reject(error));

        // Intercept failed requests and attempt to refresh the auth token
        this.api.interceptors.response.use(
            (response: any) => response,
            async (error: any) => {
                const originalRequest = error.config;

                // If the request was unauthorized and the retry flag isn't set, refresh the token
                if (originalRequest.retry || error.response.status !== 401) {
                    return Promise.reject(error);
                }

                originalRequest._retry = true;

                // Attempt to update the authorization header
                if (await this.refreshToken(error.config.baseURL)) {
                    // Retry the original request
                    return this.api(originalRequest);
                }

                return Promise.reject(error);
        });
    }

    /**
     * @description Downloads a file from the specified path and returns a promise that resolves to the file contents.
     * @param {string} path The path to download the file from.
     * @returns A promise that resolves to the file contents.
     */
    public async download(path: string): Promise<Blob> {
        try {
            const response = await this.api.get(path, { responseType: "blob" });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw error.response;
            } else {
                throw error;
            }
        }
    }

    /**
     * Sends a GET request to the specified path and returns a promise that resolves to the network response.
     * @param {string} path The path to send the GET request to.
     * @returns A promise that resolves to the network response.
     */
    public async get<T>(path: string): Promise<INetworkResponse<T>> {
        try {
            const response = await this.api.get(path);
            return this.createNetworkResponse<T>(response);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw error.response;
            } else {
                throw error;
            }
        }
    }

    /**
     * Sends a POST request to the specified path and returns a promise that resolves to the network response.
     * @param {string} path The path to send the POST request to.
     * @param {{}} body The body of the request.
     * @returns A promise that resolves to the network response.
     */
    public async post<TBody, TResponse>(path: string, body?: TBody): Promise<INetworkResponse<TResponse>> {
        try {
            const response = await this.api.post(path, body, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            return this.createNetworkResponse<TResponse>(response);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw error.response;
            } else {
                throw error;
            }
        }
    }

    /**
     * Sends a PUT request to the specified path and returns a promise that resolves to the network response.
     * @param {string} path The path to send the PUT request to.
     * @param {{}} body The body of the request.
     * @returns A promise that resolves to the network response.
     */
    public async put<TBody, TResponse>(path: string, body?: TBody): Promise<INetworkResponse<TResponse>> {
        try {
            let contentType: string;
            
            if (typeof body === "string") {
                contentType = "text/plain";
            } else if (body instanceof FormData) {
                contentType = "multipart/form-data";
            } else {
                contentType = "application/json";
            }
            
            const response = await this.api.put(path, body, { headers: { "Content-Type": contentType } });
            return this.createNetworkResponse<TResponse>(response);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw error.response;
            } else {
                throw error;
            }
        }
    }

    /**
     * Sends a PATCH request to the specified path and returns a promise that resolves to the network response.
     * @param {string} path The path to send the PATCH request to.
     * @param {{}} body The body of the request.
     * @returns A promise that resolves to the network response.
     */
    public async patch<TBody, TResponse>(path: string, body?: TBody): Promise<INetworkResponse<TResponse>> {
        try {
            const response = await this.api.patch(path, body);
            return this.createNetworkResponse<TResponse>(response);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw error.response;
            } else {
                throw error;
            }
        }
    }

    /**
     * Sends a DELETE request to the specified path and returns a promise that resolves to the network response.
     * @param {string} path The path to send the DELETE request to.
     * @returns A promise that resolves to the network response.
     */
    public async delete<T>(path: string): Promise<INetworkResponse<T>> {
        try {
            const response = await this.api.delete(path);
            return this.createNetworkResponse<T>(response);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw error.response;
            } else {
                throw error;
            }
        }
    }

    /**
     * Attempts to refresh the auth token and returns a promise that resolves to the network response.
     * @returns A promise that resolves to the network response.
     */
    public async refreshToken(baseUrl?: string): Promise<boolean> {
        baseUrl = baseUrl || process.env.REACT_APP_API_BASE_URL;
        
        try {
            const refreshToken = Cookies.get("refreshToken");

            // Use the standard axios library to avoid getting stuck in an interceptor loop
            const url = `${baseUrl}auth/token/refresh?refreshToken=${refreshToken}`;
            const response = await axios.get(url);
            
            const result = this.createNetworkResponse<IAuthenticationToken>(response);

            if (!result.isSuccessful) {
                return false;
            }

            const data = result.data as IAuthenticationToken;
            Cookies.set("token", data.access_token);
            Cookies.set("refreshToken", data.refresh_token, { expires: 7 });

            this.api.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
            
            return true;
        } catch {
            return false;
        }
    }

    private isRequestSuccessful = (status: number) => status >= 200 && status < 300;

    private createNetworkResponse = <T>(response: any): INetworkResponse<T> => {
        const networkResponse: INetworkResponse<T> = {
            status: response.status,
            isSuccessful: this.isRequestSuccessful(response.status)
        };

        if (!response.data && response.data !== 0) {
            return networkResponse;
        }

        if (networkResponse.isSuccessful) {
            networkResponse.data = response.data as T;
        } else {
            networkResponse.data = response.data as IErrorResponse;
        }

        return networkResponse;
    }
}

const networkService = new NetworkService();
export default networkService;