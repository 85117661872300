import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RedirectToLogin = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const path = location.pathname;
        const redirectUrl = path && path !== '/' 
            ? `/login?redirectUrl=${path}`
            : '/login';

        return navigate(redirectUrl);
    }, [location.pathname, navigate]);

    return null;
};

export default RedirectToLogin;