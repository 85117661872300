import { FloatingLabel, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import FormFieldValidation from "../../../enums/FormFieldValidation";

interface ITextFieldProps {
    type: string;
    label: string;
    value: string;
    disabled: boolean;
    validation: FormFieldValidation;
    validationMessage?: string;
    onChange: (value: string) => void;
}

const ValidationCssClass = (validation: FormFieldValidation): string => {
    switch (validation) {
        case FormFieldValidation.VALID:
            return "is-valid";
        case FormFieldValidation.INVALID:
        case FormFieldValidation.INVALID_EMAIL:
            return "is-invalid";
        default:
            return "";
    }
}

const IsValid = (validation: FormFieldValidation): boolean => {
    switch (validation) {
        case FormFieldValidation.VALID:
            return true;
        case FormFieldValidation.INVALID:
        case FormFieldValidation.INVALID_EMAIL:
            return false;
        default:
            return null!;
    }
}

export default function TextField(props: ITextFieldProps) {
    const { t } = useTranslation();

    const validationMessage = props.validationMessage || t("general.validation.provideValue");
    
    let validationCssClass = ValidationCssClass(props.validation);
    let isValid = IsValid(props.validation);

    return (
        <FloatingLabel  className="mb-3" controlId={`txt-${uuid()}`} label={props.label}>
            <Form.Control   type={props.type} placeholder={props.label} className={validationCssClass} value={props.value}
                            isValid={isValid} disabled={props.disabled} onChange={(e) => props.onChange(e.target.value)} />
            <Form.Control.Feedback type="invalid">
                {validationMessage}
            </Form.Control.Feedback>
        </FloatingLabel>
    );
}