import { Button, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Icon } from "../../components/icon/Icon";
import { useTranslation } from "react-i18next";
import IAuthoriseProps from "../IAuthoriseProps";
import styles from "./Login.module.scss";
import { Dispatch, SetStateAction, useState } from "react";
import Status from "../../enums/Status";
import { HttpStatusCode } from "axios";
import authenticationService from "../../services/AuthenticationService";
import IAuthenticationToken from "../../models/IAuthenticationToken";
import { NavigateFunction, useNavigate } from "react-router-dom";

/**
 * @description The login form
 */
const LoginForm = (props: IAuthoriseProps) => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [status, setStatus] = useState<Status>(Status.NEUTRAL);
    const [responseCode, setResponseCode] = useState<HttpStatusCode>(undefined!);

    const onShowPasswordClick = () => setShowPassword(!showPassword);
    const onLoginClick = () => login(email, password, setStatus, setResponseCode, props.login, props.onTokenUpdate, navigate);
    const onForgotPasswordClicked = () => navigate("/forgot-password");

    const passwordFieldType = showPassword ? "text" : "password";
    const passwordTooltipIcon = showPassword ? "EyeSlash" : "Eye";

    const passwordTooltipText = showPassword
        ? t("authentication.login.form.fields.password.hide")
        : t("authentication.login.form.fields.password.show");

    const passwordTooltip = <Tooltip id="contact-tooltip">{passwordTooltipText}</Tooltip>;

    return (
        <Form>
            <Form.Group className="mb-3" controlId="txt-login-email">
                <Form.Label>{t("authentication.login.form.fields.email.label")}</Form.Label>
                <Form.Control   placeholder={t("authentication.login.form.fields.email.placeholder")}
                                type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="txt-login-password">
                <Row>
                    <Col>
                        <Form.Label>{t("authentication.login.form.fields.password.label")}</Form.Label>
                    </Col>
                    <Col xs="auto">
                        <Button className="form-text small text-body-secondary p-0"
                                type="button" variant="link" onClick={onForgotPasswordClicked}>
                            {t("authentication.login.form.forgot")}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup className={`${styles.password} input-group-merge`}>
                            <Form.Control   placeholder={t("authentication.login.form.fields.password.placeholder")}
                                            type={passwordFieldType} value={password} onChange={(e) => setPassword(e.target.value)} />
                            <OverlayTrigger overlay={passwordTooltip} placement="left">
                                <InputGroup.Text onClick={onShowPasswordClick}>
                                    <Icon iconName={passwordTooltipIcon} />
                                </InputGroup.Text>
                            </OverlayTrigger>
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>

            {
                (
                    status === Status.LOAD_ERROR &&
                    responseCode === HttpStatusCode.Unauthorized &&
                    <div className="alert alert-danger" role="alert">
                        {t("authentication.login.exceptions.invalidCredentials")}
                    </div>
                ) ||
                (
                    status === Status.LOAD_ERROR &&
                    <div className="alert alert-danger" role="alert">
                        {t("authentication.login.exceptions.general")}
                    </div>
                )
            }

            <Button variant="primary"
                    size="lg"
                    type="submit"
                    className="w-100 mb-3"
                    disabled={status === Status.LOADING}
                    onClick={onLoginClick}>
                {t("authentication.login.form.submit")}
            </Button>

        </Form>
    );
};

export default function Login(props: IAuthoriseProps) {
    const { t } = useTranslation();

    return (
        <>
            <h1 className="display-4 text-center mb-3">{t("authentication.login.title")}</h1>
            <p className="text-body-secondary text-center mb-5">{t("authentication.login.subtitle")}</p>
            <LoginForm {...props} />
        </>
    );
}

function login (
    email: string,
    password: string,
    setStatus: Dispatch<SetStateAction<Status>>,
    setResponseCode: Dispatch<SetStateAction<HttpStatusCode>>,
    storeToken: (token: IAuthenticationToken) => void,
    updateToken: () => void,
    navigate: NavigateFunction
) {
    setStatus(Status.LOADING);

    authenticationService.getToken(email, password)
        .then((result) => {
            setStatus(Status.LOAD_SUCCESS);
            setResponseCode(HttpStatusCode.Ok);

            storeToken(result);
            updateToken();

            redirectToApp(navigate);
        })
        .catch((response) => {
            setStatus(Status.LOAD_ERROR);
            setResponseCode(response.status || HttpStatusCode.InternalServerError);
        });
}

function redirectToApp(navigate: NavigateFunction) {
    const query = new URLSearchParams(window.location.search);
    const redirectUrl = query.has("redirectUrl")
        ? query.get("redirectUrl")!
        : '/';

    navigate(redirectUrl);
}