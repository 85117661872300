import FormFieldValidation from "../../../enums/FormFieldValidation";
import { useEffect, useRef } from "react";
import styles from './RichTextField.module.scss';
import Quill from 'quill';
import "quill/dist/quill.snow.css";
import { modules, formats } from "./QuillConfig";
import { mergeCss, mergeStyles } from "@fluentui/merge-styles";
import IRichTextFieldProps from "./IRichTextFieldProps";

export default function RichTextField(props: IRichTextFieldProps) {
    const height = props.height || 300;
    const heightStyle = mergeCss({ height: `${height}px` });
    const quillStyle = mergeStyles(styles.editor, heightStyle);

    const quillRef = useRef<Quill | null>(null);
    const quillContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (quillContainerRef.current && !quillRef.current) {
            quillRef.current = new Quill(quillContainerRef.current, {
                theme: 'snow',
                modules,
                formats,
            });
            
            quillRef.current.on('editor-change', (eventName: string) => {
                if (eventName === 'selection-change' && quillRef.current) {
                    props.onChange(quillRef.current.root.innerHTML);
                }
            });
        }
    }, []);
  
    return (
        <div className="d-block mb-3">
            <label className={styles.label}>{props.label}</label>
            <div ref={quillContainerRef} className={quillStyle} />
            {
                props.validation === FormFieldValidation.INVALID && props.validationMessage &&
                <span className="d-block fs-sm text-danger mb-3">{props.validationMessage}</span>
            }
        </div>
    );
}