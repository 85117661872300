import { useTranslation } from "react-i18next";
import ITicket from "../../../../models/ITicket";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import Status from "../../../../enums/Status";
import IAccount from "../../../../models/IAccount";
import IAttachment from "../../../../models/IAttachment";
import accountService from "../../../../services/AccountService";
import ticketService from "../../../../services/helpdesk/TicketService";
import { Alert, Container, ListGroup, Spinner } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import NewAttachmentForm from "./NewAttachmentForm";
import Attachment from "./Attachment";

export default function AttachmentsTab({ ticket }: { ticket: ITicket }) {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [attachments, setAttachments] = useState<IAttachment[]>([]);
    const [account, setAccount] = useState<IAccount>(null!);

    const loadAttachments = useCallback(() => getAttachments(ticket.id, setAttachments, setStatus), [ticket.id, setAttachments, setStatus]);
    useEffect(() => {
        getAccount(setAccount);
        loadAttachments();
    }, [loadAttachments, ticket.id]);
    
    return (
        <Container>
            {
                (
                    status === Status.LOADING &&
                    <div className="text-center">
                        <Spinner animation="border" variant="dark" />
                    </div>
                ) ||
                (
                    status === Status.LOAD_ERROR &&
                    <Alert variant="danger">{t("helpdesk.exceptions.loadAttachments")}</Alert>
                ) ||
                (
                    [Status.LOAD_SUCCESS, Status.SAVING, Status.SAVE_SUCCESS, Status.SAVE_ERROR].includes(status) &&
                    <div className="d-block">
                        {
                            (
                                attachments.length === 0 &&
                                <Alert variant="info">{t("helpdesk.attachments.noAttachments")}</Alert>
                            ) ||
                            <ListGroup variant="flush">
                                {attachments.map((attachment) => <Attachment key={uuid()} ticketId={ticket.id} attachment={attachment} />)}
                            </ListGroup>
                        }
                        <NewAttachmentForm ticketId={ticket.id} reloadAttachments={loadAttachments} />
                    </div>
                )
            }
        </Container>
    );
}

function getAccount(setAccount: Dispatch<SetStateAction<IAccount>>) {
    try {
        accountService.getAccount()
            .then(account => setAccount(account))
            .catch(() => setAccount(null!));
    } catch {
        setAccount(null!);
    }
}

function getAttachments(
    ticketId: number,
    setAttachments: Dispatch<SetStateAction<IAttachment[]>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    try {
        ticketService.getAttachments(ticketId)
            .then(attachments => {
                setAttachments(attachments);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    } catch {
        setAttachments([]);
        setStatus(Status.LOAD_ERROR);
    }
}