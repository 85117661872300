import IMessage from "../models/IMessage";
import networkService from "./NetworkService";

class CommunicationService {

    /**
     * @description Sends a message relating to a helpdesk ticket
     * @param {IMessage} message The message to send
     */
    async postMessage(message: IMessage): Promise<boolean> {
        const result = await networkService.put("communication", message);
        return result.isSuccessful;
    }

}

const communicationService = new CommunicationService();
export default communicationService;