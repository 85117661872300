import i18n from 'i18next';
import PageHeader from '../components/pageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import IAccount from '../models/IAccount';
import { useEffect, useState } from 'react';
import Status from '../enums/Status';
import accountService from '../services/AccountService';

const greeting = (name?: string) => {
    const now = new Date();
    const hour = now.getHours();

    let string = "greeting.welcome";

    if (hour > 5 && hour < 12) {
        string = "greeting.morning";
    } else if (hour < 17) {
        string = "greeting.afternoon";
    } else if (hour < 22) {
        string = "greeting.evening";
    }

    return name
        ? i18n.t(`${string}.withName`, { name: name })
        : i18n.t(`${string}.withoutName`);
}

export default function Greeting() {
    const { t } = useTranslation();

    const [account, setAccount] = useState<IAccount>(undefined!);
    const [status, setStatus] = useState<Status>(Status.NEUTRAL);

    useEffect(() => getAccount(setAccount, setStatus), []);
    
    if (status === Status.LOAD_SUCCESS && account) {
        return <PageHeader title={greeting(account.firstName)} subtitle={t("dolphin.application.short")} />;
    } else {
        return <PageHeader title={greeting()} subtitle={t("dolphin.application.short")} />;
    }
}

function getAccount(
    setAccount: React.Dispatch<React.SetStateAction<IAccount>>,
    setStatus: React.Dispatch<React.SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    accountService.getAccount()
        .then(account => {
            setAccount(account);
            setStatus(Status.LOAD_SUCCESS);
        })
        .catch(() => setStatus(Status.LOAD_ERROR));
}