import i18n from "i18next";
import dayjs from "dayjs";
import ICommentProps from "./ICommentProps";
import { Col, Row } from "react-bootstrap";

const CommentAuthor = (props: ICommentProps) => {
    const comment = props.comment;
    if (comment.identity.toLowerCase() === props.me.username.toLowerCase()) {
        return i18n.t("helpdesk.comments.me");
    }

    let author: string;

    if (comment.content.startsWith("[Posted by")) {
        author = comment.content.replace("[Posted by ", "").split("]")[0];
    } else {
        author = comment.author;
    }

    if (author.includes("#")) {
        const identity = author.split("#")[0];
        return identity.toLowerCase() === props.me.username.toLowerCase()
            ? i18n.t("helpdesk.comments.me")
            : author.split("#")[1];
    }

    return author;
}

const CommentAuthorInitials = (author: string) => {
    if (author === `${i18n.t("helpdesk.comments.me")}`) {
        return i18n.t("helpdesk.comments.meInitials");
    }

    author = author.replace(" (Dolphin IT Solutions)", "");

    if (!author.includes(" ")) {
        return author.charAt(0);
    }

    const names = author.split(" ");
    return names[0].charAt(0) + names[1].charAt(0);
};

const CommentContent = (content: string) => {
    if (content.startsWith("[Posted by")) {
        return content.split("]")[1];
    }

    return content;
}

function CommentLeftAuthor(props: ICommentProps) {
    const comment = props.comment;

    const author = CommentAuthor(props);
    const timeStamp = dayjs(comment.posted).format("DD/MM/YYYY HH:mm");

    return (
        <div className="comment mb-3">
            <Row className="align-items-center">
                <Col xs="auto">
                    <a className="avatar" href="profile-posts.html">
                    <span className="avatar-title rounded-circle">
                        {CommentAuthorInitials(author)}
                    </span>
                    </a>
                </Col>
                <Col className="ms-n2">
                    <div className="comment-body">
                        <Row>
                            <Col>
                                <h5 className="comment-title">{author}</h5>
                            </Col>
                            <Col xs="auto">
                                <time className="comment-time">{timeStamp}</time>
                            </Col>
                        </Row>

                        <p className="comment-text">{CommentContent(comment.content)}</p>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

function CommentRightAuthor(props: ICommentProps) {
    const comment = props.comment;

    const author = CommentAuthor(props);
    const timeStamp = dayjs(comment.posted).format("DD/MM/YYYY HH:mm");

    return (
        <div className="comment mb-3">
            <Row className="align-items-center">
                <Col />
                <Col className="ms-auto text-end">
                    <div className="comment-body">
                        <Row>
                            <Col>
                                <h5 className="comment-title">{author}</h5>
                            </Col>
                            <Col xs="auto">
                                <time className="comment-time">{timeStamp}</time>
                            </Col>
                        </Row>

                        <p className="comment-text">{CommentContent(comment.content)}</p>
                    </div>
                </Col>
                <Col xs="auto">
                    <a className="avatar" href="profile-posts.html">
                    <span className="avatar-title rounded-circle">
                        {CommentAuthorInitials(author)}
                    </span>
                    </a>
                </Col>
            </Row>
        </div>
    );
}

export default function Comment(props: ICommentProps) {
    const author = CommentAuthor(props);
    
    return author === i18n.t("helpdesk.comments.me")
        ? CommentRightAuthor(props)
        : CommentLeftAuthor(props);
}