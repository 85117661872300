import IStaticValue from "../../models/IStaticValue";
import networkService from "../NetworkService";

/**
 * @description Service for managing helpdesk tickets
 */
class HelpdeskService {

    /**
     * @description Fetches the skills supported for the user's helpdesk
     */
    public async getSkills() : Promise<IStaticValue[]> {
        const result = await networkService.get<IStaticValue[]>("helpdesk/skills");

        if (result.isSuccessful) {
            return result.status === 200
                ? result.data as IStaticValue[]
                : [];
        }

        throw result.data;
    }

    /**
     * @description Fetches the tasks supported for the user's helpdesk
     */
    public async getTasks() : Promise<IStaticValue[]> {
        const result = await networkService.get<IStaticValue[]>("helpdesk/tasks");

        if (result.isSuccessful) {
            return result.status === 200
                ? result.data as IStaticValue[]
                : [];
        }

        throw result.data;
    }
}

const helpdeskService = new HelpdeskService();
export default helpdeskService;