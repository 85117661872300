/**
 * @description SLA status.
 */
enum SLA {

    /**
     * @description The SLA has been met.
     */
    MET,

    /**
     * @description The SLA has not been met yet.
     */
    NOT_MET_YET,

    /**
     * @description The SLA has been missed.
     */
    MISSED
}

export default SLA;