import { useTranslation } from "react-i18next";
import ITicket from "../../models/ITicket";
import { Table } from "react-bootstrap";
import { mergeStyles } from "@fluentui/merge-styles";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

interface ITicketsTableProps {
    /**
     * @description The tickets to display
     */
    tickets: ITicket[];

    /**
     * @description The current
     */
    page: number;

    /**
     * @description The number of tickets to display per page
     */
    pageSize: number;
}

const Row = (props: { ticket: ITicket }) => {
    const navigate = useNavigate();
    const rowStyle = mergeStyles({ cursor: "pointer" });

    const logged = dayjs(props.ticket.created).format("DD/MM/YYYY HH:mm");

    return (
        <tr className={rowStyle} onClick={() => navigate(`/helpdesk/ticket/${props.ticket.id}`)}>
            <th scope="row">
                <span className="d-block fs-sm fw-bold text-muted">{props.ticket.ticketNumber}</span>
                <span className="d-block fw-light text-primary">{props.ticket.title}</span>
            </th>
            <td>{props.ticket.client}</td>
            <td>{props.ticket.type}</td>
            <td>
                {
                    (() => {
                        if (props.ticket.step.name === "Awaiting Triage") {
                            return null;
                        }
                        else {
                            const assignedUser = props.ticket.clientAssignee ?? props.ticket.assignee;
                            return assignedUser ? (
                            <a href={`mailto:${assignedUser.emailAddress}`}>
                                {assignedUser.name}
                            </a>
                            ) : null;
                        }
                    })()
                }
            </td>
            <td>{logged}</td>
        </tr>
    );
};

export default function TicketsTable(props: ITicketsTableProps) {
    const { t } = useTranslation();

    const tickets = sliceTickets(props.tickets, props.page, props.pageSize);
    const tableVariant = tickets.length > 0 ? "hover" : undefined;

    return (
        <Table responsive size="sm" variant={tableVariant} className="card-table table-vcenter text-nowrap">
            <thead>
                <tr>
                    <th scope="col">{t("helpdesk.table.ticket")}</th>
                    <th scope="col">{t("helpdesk.table.client")}</th>
                    <th scope="col">{t("helpdesk.table.type")}</th>
                    <th scope="col">{t("helpdesk.table.assignee")}</th>
                    <th scope="col">{t("helpdesk.table.logged")}</th>
                </tr>
            </thead>
            <tbody className="list fs-base">
                {
                    tickets.length === 0 &&
                    <tr className="text-center bg-info">
                        <td colSpan={4} className="fw-bold text-light">
                            {t("helpdesk.table.noTicketsForFilter")}
                        </td>
                    </tr>
                }
                {
                    tickets.map((ticket, index) => <Row key={index} ticket={ticket} />)
                }
            </tbody>
        </Table>
    );
}

function sliceTickets(tickets: ITicket[], page: number, pageSize: number) {
    if (pageSize === 0) {
        return tickets;
    }

    const startIndex = (page - 1) * pageSize,
        endIndex = startIndex + pageSize;

    return tickets
            .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
            .slice(startIndex, endIndex);
}