import { Form } from "react-bootstrap";

interface IRangeProps {
    min: number;
    max: number;
    step: number;
    value: number;
    label: string;
    valueLabel: string;
    valueLabelColourCssClass: string;
    onChange: (value: number) => void;
}

export default function Range(props: IRangeProps) {
    return (
        <div className="d-block mb-3">
            <Form.Label>{props.label}</Form.Label>
            <div className="d-flex align-items-center">
                <div className="flex-grow-1 me-3">
                    <Form.Range min={props.min} max={props.max} step={props.step} value={props.value}
                                onChange={(e) => props.onChange(parseInt(e.target.value))} />
                </div>
                <span className={`fw-bold ${props.valueLabelColourCssClass}`}>{props.valueLabel}</span>
            </div>
        </div>
    );
}