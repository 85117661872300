import { FloatingLabel, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormFieldValidation from "../../../enums/FormFieldValidation";

interface IDropdownOption {
    value: number | undefined;
    label: string;
}

interface IDropdownProps {
    label: string;
    options: IDropdownOption[];
    value: number | undefined;
    disabled: boolean;
    validation: FormFieldValidation;
    validationMessage?: string;
    onChange: (value: number) => void;
}

const ValidationCssClass = (validation: FormFieldValidation): string => {
    switch (validation) {
        case FormFieldValidation.VALID:
            return "is-valid";
        case FormFieldValidation.INVALID:
            return "is-invalid";
        default:
            return "";
    }
}

const IsValid = (validation: FormFieldValidation): boolean => {
    switch (validation) {
        case FormFieldValidation.VALID:
            return true;
        case FormFieldValidation.INVALID:
        case FormFieldValidation.INVALID_EMAIL:
            return false;
        default:
            return null!;
    }
}

export default function Dropdown(props: IDropdownProps) {
    const { t } = useTranslation();

    const validationMessage = props.validationMessage || t("general.validation.provideValue");
    
    let validationCssClass = ValidationCssClass(props.validation);
    let isValid = IsValid(props.validation);
    
    return (
        <FloatingLabel controlId="drp-new-user" label={props.label}>
            <Form.Select    title={props.label} value={props.value} disabled={props.disabled}
                            isValid={isValid} className={validationCssClass}
                            onChange={(e) => props.onChange(Number(e.target.value))}>
                <option>{t("general.pleaseSelect")}</option>
                {props.options.map(s => <option key={s.value} value={s.value}>{s.label}</option>)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {validationMessage}
            </Form.Control.Feedback>
        </FloatingLabel>
    );
}