import { Button, Col, ListGroupItem, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import IAttachment from "../../../../models/IAttachment";
import { Icon } from "../../../../components/icon/Icon";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useState } from "react";
import Status from "../../../../enums/Status";
import i18n from 'i18next';
import ticketService from "../../../../services/helpdesk/TicketService";

interface IAttachmentProps {
    ticketId: number;
    attachment: IAttachment;
}

export default function Attachment(props: IAttachmentProps) {
    const { t } = useTranslation();
    const attachment = props.attachment;

    const [status, setStatus] = useState<Status>(Status.NEUTRAL);

    const download = () => downloadAttachment(props.ticketId, attachment, setStatus);

    const tooltip = <Tooltip id={`tooltip-download-${attachment.id}`}>Download</Tooltip>;

    let author = attachment.author.split("#")[1];
    author = author.replace(" (Dolphin IT Solutions)", "");

    return (
        <ListGroupItem>
            <Row className="align-items-center">
                <Col>
                    <h4 className="mb-1 name">
                        {attachment.name}
                    </h4>
                    <p className="card-text small text-body-secondary mb-1">
                        {attachment.size}
                    </p>
                    <p className="card-text small text-body-secondary">
                        {t("helpdesk.attachments.author", { author })}
                    </p>
                </Col>
                <Col xs="auto">
                    {
                        (status === Status.LOADING && <Spinner size="sm" animation="border" variant="dark" />) ||
                        <OverlayTrigger placement="left" overlay={tooltip}>
                            <Button variant="light" onClick={download}>
                                <Icon iconName="Download" />
                            </Button>
                        </OverlayTrigger>
                    }
                </Col>
            </Row>
        </ListGroupItem>
    );
}

function downloadAttachment(
    ticketId: number,
    attachment: IAttachment,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    try {
        ticketService.downloadAttachment(ticketId, attachment.id)
            .then(result => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(result);
                link.download = attachment.name;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => {
                alert(i18n.t("helpdesk.exceptions.downloadAttachment"));
                setStatus(Status.LOAD_ERROR);
            });
    } catch {
        alert(i18n.t("helpdesk.exceptions.downloadAttachment"));
        setStatus(Status.LOAD_ERROR);
    }
}