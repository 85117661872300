import { Alert, Container, Spinner } from "react-bootstrap";
import ITicket from "../../../../models/ITicket";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import Status from "../../../../enums/Status";
import IComment from "../../../../models/IComment";
import ticketService from "../../../../services/helpdesk/TicketService";
import { v4 as uuid } from "uuid";
import IAccount from "../../../../models/IAccount";
import accountService from "../../../../services/AccountService";
import NewCommentForm from "./NewCommentForm";
import Comment from "./Comment";

export default function CommentsTab({ ticket }: { ticket: ITicket }) {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [comments, setComments] = useState<IComment[]>([]);
    const [account, setAccount] = useState<IAccount>(null!);

    const loadComments = useCallback(() => getComments(ticket.id, setComments, setStatus), [ticket.id, setComments, setStatus]);
    useEffect(() => {
        getAccount(setAccount);
        loadComments();
    }, [loadComments, ticket.id]);

    return (
        <Container>
            {
                (
                    status === Status.LOADING &&
                    <div className="text-center">
                        <Spinner animation="border" variant="dark" />
                    </div>
                ) ||
                (
                    status === Status.LOAD_ERROR &&
                    <Alert variant="danger">{t("helpdesk.exceptions.loadComments")}</Alert>
                ) ||
                (
                    [Status.LOAD_SUCCESS, Status.SAVING, Status.SAVE_SUCCESS, Status.SAVE_ERROR].includes(status) &&
                    <div className="d-block">
                        {
                            (
                                comments.length === 0 &&
                                <Alert variant="info">{t("helpdesk.comments.noComments")}</Alert>
                            ) ||
                            <>
                                {comments.map((comment) => <Comment key={uuid()} comment={comment} me={account} />)}
                                <hr />
                            </>
                        }
                        <NewCommentForm ticketId={ticket.id} onCommentPosted={loadComments} />
                    </div>
                )
            }
        </Container>
    );
}

function getAccount(setAccount: Dispatch<SetStateAction<IAccount>>) {
    try {
        accountService.getAccount()
            .then(account => setAccount(account))
            .catch(() => setAccount(null!));
    } catch {
        setAccount(null!);
    }
}

function getComments(
    ticketId: number,
    setComments: Dispatch<SetStateAction<IComment[]>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    try {
        ticketService.getComments(ticketId)
            .then(comments => {
                setComments(comments);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    } catch {
        setComments([]);
        setStatus(Status.LOAD_ERROR);
    }
}