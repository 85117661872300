import { ListGroupItem } from "react-bootstrap";
import IMessage from "../../../../models/IMessage";
import dayjs from "dayjs";
import { Icon } from "../../../../components/icon/Icon";

const MessageDate = ({ date }: { date: string | Date }) => {
    const today = new Date();
    date = new Date(date);

    let timeStamp = dayjs(date).format("YYYY-MM-DD HH:mm");
    
    if (today.getDate() === date.getDate() &&
        today.getMonth() === date.getMonth() &&
        today.getFullYear() === date.getFullYear()
    ) {
        timeStamp = dayjs(date).format("HH:mm");
    }

    return (
        <div className="d-flex align-items-center">
            <Icon iconName="Clock" endMargin={2} /> {timeStamp}
        </div>
    );
}

const Author = ({ author }: { author: string }) => {
    return (
        <div className="d-flex align-items-center">
            <Icon iconName="PersonCircle" endMargin={2} /> {author}
        </div>
    );
}

export default function Message({ message }: { message: IMessage }) {
    return (
        <ListGroupItem key={message.id}>
            <h3 className="mb-1">{message.subject}</h3>
            
            {message.author && <Author author={message.author} />}
            <MessageDate date={message.date} />
            
            <div className="mt-2" dangerouslySetInnerHTML={{ __html: message.body }} />
        </ListGroupItem>
    );
}