import AuthenticationProvider from "../enums/AuthenticationProvider";
import IAuthenticationToken from "../models/IAuthenticationToken";
import IImplicitFlow from "../models/IImplicitFlow";
import networkService from "./NetworkService";

/**
 * @description Handles all authentication requests
 */
class AuthenticationService {
  
    /**
     * @description Authenticates a user using classic authentication and returns an authorisation token
     * @param {string} username The user's logon name
     * @param {string} password The user's password
     * @returns The authentication token
     */
    public async getToken(username: string, password: string): Promise<IAuthenticationToken> {
        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        const result = await networkService.post("auth/token", formData);

        if (result.isSuccessful) {
            return result.data as IAuthenticationToken;
        }

        throw result;
    }

    /**
     * @description Refreshes an authorisation token
     */
    public async refreshToken(): Promise<IAuthenticationToken> {
        const result = await networkService.get("auth/token/refresh");
        
        if (result.isSuccessful) {
            return result.data as IAuthenticationToken;
        }

        throw result;
    }

    /**
     * @description Logs a user into an OIDC provider and returns an authorisation token
     * @param {AuthenticationProvider} provider The flag indicating which provider to log in with 
     * @param {string} code The code returned by the OIDC provider
     * @param {string} redirectUri The URL to redirect the user to upon successfully authenticating
     */
    public async getOidcToken(provider: AuthenticationProvider, code: string, redirectUri: string): Promise<IAuthenticationToken> {
        const path = `auth/signin-oidc?providerId=${provider}&code=${code}&redirectUri=${redirectUri}`;
        const result = await networkService.get(path);

        if (result.isSuccessful) {
            return result.data as IAuthenticationToken;
        }

        throw result;
    }

    /**
     * @description Fetches the URL used to log in with an external provider
     * @param {AuthenticationProvider} provider The flag indicating which provider to log in with
     */
    public async getExternalLoginUrl(provider: AuthenticationProvider): Promise<string> {
        const result = await networkService.get(`auth/externalloginurl?providerId=${provider}`);

        if (result.isSuccessful) {
            return result.data as string;
        }

        throw result.data;
    }

    /**
     * @description Fetches the form data to be used to authenticate with an external provider
     * @param {AuthenticationProvider} provider The flag indicating which provider to log in with
     * @param {string} redirectUri The URL to redirect the user to upon successfully authenticating
     */
    public async getExternalLoginData(provider: AuthenticationProvider, redirectUri: string): Promise<IImplicitFlow> {
        const path = `auth/externallogindata?providerId=${provider}&redirectUri=${redirectUri}`;
        const result = await networkService.get(path);

        if (result.isSuccessful) {
            return result.data as IImplicitFlow;
        }

        throw result.data;
    }

    /**
     * @description Sends a password reset email to the user
     */
    public async sendPasswordResetEmail(email: string): Promise<void> {
        const passwordResetUrl = `${process.env.REACT_APP_BASE_URL}reset-password`;

        const formData = new FormData();
        formData.append("EmailAddress", email);
        formData.append("AbsoluteUrl", passwordResetUrl);
        formData.append("TokenParamName", "token");
        formData.append("EmailParamName", "email");

        const result = await networkService.post("auth/password/forgot", formData);

        if (!result.isSuccessful) {
            throw result;
        }
    }

    /**
     * @description Resets a user's password
     */
    public async resetPassword(email: string, password: string, token: string): Promise<void> {
        const formData = new FormData();
        formData.append("EmailAddress", email);
        formData.append("Password", password);
        formData.append("Token", token);

        const result = await networkService.post("auth/password/reset", formData);

        if (!result.isSuccessful) {
            throw result;
        }
    }
}

const authenticationService = new AuthenticationService();
export default authenticationService;