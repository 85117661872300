import { Col, Container, Row } from "react-bootstrap";
import NewTicketForm from "./Form";
import { useTranslation } from "react-i18next";
import PageHeader from "../../components/pageHeader/PageHeader";
import NewTicketGuidance from "./Guidance";

export default function NewTicket() {
    const { t } = useTranslation();

    return (
        <>
            <PageHeader title={t("helpdesk.new")} subtitle={t("helpdesk.titles.hd")} />
            <Container>
                <Row>
                    <Col xs={12} lg={8}>
                        <NewTicketForm />
                    </Col>
                    <Col xs={12} lg={4} >
                        <NewTicketGuidance />
                    </Col>
                </Row>
            </Container>
        </>
    );
}