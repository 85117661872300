import { Button } from "react-bootstrap";
import accountService from "../../../services/AccountService";
import ITicketNumberProps from "./ITicketNumberProps";
import { Icon } from "../../../components/icon/Icon";

const createUrl = (baseUrl: string, databaseId: number, ticketId: number) =>
    `${baseUrl}tasks/db/${databaseId}/element/${ticketId}`;

const TicketNumberText = (props: ITicketNumberProps) =>
    <p className="lead text-muted">{props.ticketNumber}</p>;

const TicketNumberLink = (props: ITicketNumberProps) => {
    return (
        <a href={props.link!} className="btn btn-link" target="_blank" rel="noreferrer">
            {props.ticketNumber}
        </a>
    );
}

const BackButton = () => {
    const onClick = () => window.history.back();

    return (
        <Button variant="light" size="sm" onClick={onClick}>
            <Icon iconName="ChevronLeft" />
        </Button>
    );
}

export default function TicketNumber(props: ITicketNumberProps) {
    const roles = accountService.getRoles();

    if (!roles.includes("Administrator") &&
        !roles.includes("Helpdesk") &&
        !roles.includes("Client")) {
        return <TicketNumberText {...props} />;
    }

    const bimsUrl = process.env.REACT_APP_BIMS_BASE_URL!;
    const databaseId = Number(process.env.REACT_APP_DOLPHIN_HD_DB_ID);

    const url = createUrl(bimsUrl, databaseId, props.ticketId);

    return (
        <div className="d-flex align-items-center mb-3">
            <BackButton />
            <TicketNumberLink {...props} link={url} />
        </div>
    );
}