import { useState } from "react";
import { Button, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Icon } from "../icon/Icon";

/**
 * @description Props for ContactOffcanvas component
 */
interface IContactOffcanvasProps {

    /**
     * @description Whether the offcanvas is visible or not
     */
    show: boolean;

    /**
     * @description Callback for when the offcanvas is dismissed
     */
    onDismiss: () => void;
}

/**
 * @description Component for the contact offcanvas
 * @param {IContactOffcanvasProps} props Props for ContactOffcanvas component
 * @returns A React function component
 */
const ContactOffcanvas = (props: IContactOffcanvasProps) => {
    const { t } = useTranslation();
    
    return (
        <Offcanvas show={props.show} onHide={props.onDismiss} placement="bottom">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("contact.title")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <p className="lead">{t("contact.subtitle")}</p>
                <p>
                    <span className="fw-bold">{t("contact.email.title")}</span><br />
                    <a href={`mailto:${t("contact.email.address")}`}>{t("contact.email.address")}</a>
                </p>
                <p>
                    <span className="fw-bold">{t("contact.phone.title")}</span><br />
                    <a href={`tel:${t("contact.phone.number")}`}>{t("contact.phone.number")}</a>
                </p>
                <p>
                    <span className="fw-bold">{t("contact.address.title")}</span><br />
                    <address>{t("contact.address.address")}</address>
                </p>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

/**
 * @description Component for the contact button in the sidebar that toggles the contact offcanvas
 * @returns A React function component
 */
const Contact = () => {
    const { t } = useTranslation();

    const [showContactOffcanvas, setShowContactOffcanvas] = useState(false);
    
    const onContactButtonClicked = () => setShowContactOffcanvas(true);
    const dismissContactOffcanvas = () => setShowContactOffcanvas(false);

    return (
        <>
            <OverlayTrigger overlay={<Tooltip id="contact-tooltip">{t("navigation.contact")}</Tooltip>} placement="top">
                <Button variant="link" className="navbar-user-link" onClick={onContactButtonClicked}>
                    <Icon iconName="Telephone" />
                </Button>
            </OverlayTrigger>
            <ContactOffcanvas show={showContactOffcanvas} onDismiss={dismissContactOffcanvas} />
        </>
    );
};

export default Contact;