import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TicketStatus from "../../enums/TicketStatus";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Status from "../../enums/Status";
import ITicket from "../../models/ITicket";
import ticketService from "../../services/helpdesk/TicketService";
import PageHeader from "../../components/pageHeader/PageHeader";
import TicketsCard from "../../helpdesk/tickets/TicketsCard";
import { Spinner, Alert } from "react-bootstrap";

const SelectedTicketStatus = (path: string) => {
    switch (path) {
        case "/helpdesk/tickets/onhold":
            return TicketStatus.ON_HOLD;
        default:
            return TicketStatus.OPEN;
    }
}

export default function Tickets() {
    const { t } = useTranslation();
    
    const location = useLocation();
    const path = location.pathname;

    const [tickets, setTickets] = useState<ITicket[]>([]);
    const [status, setStatus] = useState<Status>(Status.LOADING);

    useEffect(() => getData(path, setTickets, setStatus), [path]);
    
    if (status === Status.LOADING) {
        return (
            <>
                <div className="text-center">
                    <Spinner animation="border" variant="dark" />
                </div>
            </>
        );
    }

    if (status === Status.LOAD_ERROR) {
        return (
            <>
                <Alert variant="danger">{t("helpdesk.exceptions.loadTickets")}</Alert>
            </>
        );
    }

    return (
        <>
         <PageHeader title={t("administration.reporting")} subtitle={t("administration.titles.hd")} />
         <TicketsCard tickets={tickets} />
        </>
    );
}

function getData(
    path: string,
    setTickets: Dispatch<SetStateAction<ITicket[]>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);
    
    try {

        (async () => {
            //const account = await accountService.getAccount();
            //const roles = accountService.getRoles();

            let tickets = await ticketService.getAllTickets();

            setTickets(tickets);
            
            setStatus(Status.LOAD_SUCCESS);
        })();
    } catch {
        setStatus(Status.LOAD_ERROR)
    }
}