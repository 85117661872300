import { Col, Container, Row } from "react-bootstrap";
import ITicket from "../../../models/ITicket";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import dayjs from "dayjs";
import SLA from "../../../enums/SLA";

interface ISLA {
    target: Date;
    actual?: Date;
    met: SLA;
}

interface ISLASectionProps {
    sla: ISLA;
    titleKey: string;
    targetLabelKey: string;
    actualLabelKey: string;
    metLabelKey: string;
}

const now = new Date();

const getSla = (target: Date, actual?: Date): ISLA => {
    const status = actual
        ? (actual! < target ? SLA.MET : SLA.MISSED)
        : (now < target ? SLA.NOT_MET_YET : SLA.MISSED);

    const sla: ISLA = {
        target: target,
        actual: actual,
        met: status
    };

    return sla;
};

const SLASection = (props: ISLASectionProps) => {
    const { t } = useTranslation();

    const timestampFormat = "DD/MM/YYYY HH:mm";

    const targetFormatted = dayjs(props.sla.target).format(timestampFormat);
    const actualFormatted = props.sla.actual
        ? dayjs(props.sla.actual!).format(timestampFormat)
        : undefined;

    return (
        <Col xs={12} lg={6}>
            <h4>{t(props.titleKey)}</h4>
            <h5>{t(props.targetLabelKey)}</h5>
            <p className="mb-2">{targetFormatted}</p>
            
            {
                props.sla.actual &&
                <>
                    <h5>{t(props.actualLabelKey)}</h5>
                    <p className="mb-2">{actualFormatted}</p>
                </>
            }

            <h5>{t(props.metLabelKey)}</h5>
            {
                {
                    [SLA.MET]: <p className="mb-2 text-success">{t("general.yes")}</p>,
                    [SLA.MISSED]: <p className="mb-2 text-danger">{t("general.no")}</p>,
                    [SLA.NOT_MET_YET]: <p className="mb-2 text-warning">{t("general.notYet")}</p>
                }[props.sla.met]
            }
            
        </Col>
    );
}

const PriorityValue = (value: number) => (
    <p>
        {
            {
                1: <span className="text-success">{i18n.t("helpdesk.details.priority.ratings.low")}</span>,
                2: <span className="text-warning">{i18n.t("helpdesk.details.priority.ratings.medium")}</span>,
                3: <span className="text-danger">{i18n.t("helpdesk.details.priority.ratings.high")}</span>,
                _: <span>{value}</span>
            }[value]
        }
    </p>
);

export default function TicketSLATab({ ticket }: { ticket: ITicket }) {
    const { t } = useTranslation();

    const responseSla = getSla(ticket.targetResponseTime!, ticket.responded);
    const resolutionSla = getSla(ticket.targetCompletionTime!, ticket.completed);

    return (
        <Container>
            <Row>
                <Col xs={12} lg={4} className="mb-3 mb-lg-0 border-end-lg">
                    <div className="mb-3">
                        <h5>{t("helpdesk.details.priority.severity")}</h5>
                        {PriorityValue(ticket.severity)}
                    </div>
                    <div className="mb-3">
                        <h5>{t("helpdesk.details.priority.impactToBusiness")}</h5>
                        {PriorityValue(ticket.businessImpact)}
                    </div>
                </Col>

                <Col xs={12} lg={8}>
                    <Row>
                        <SLASection sla={responseSla}
                            titleKey="helpdesk.sla.titles.response.sla"
                            targetLabelKey="helpdesk.sla.target.response"
                            actualLabelKey="helpdesk.sla.actual.response"
                            metLabelKey="helpdesk.sla.met.response" />
                                
                        <SLASection sla={resolutionSla}
                            titleKey="helpdesk.sla.titles.resolution.sla"
                            targetLabelKey="helpdesk.sla.target.resolution"
                            actualLabelKey="helpdesk.sla.actual.resolution"
                            metLabelKey="helpdesk.sla.met.resolution" />
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}