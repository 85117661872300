import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import Status from "../../enums/Status";
import authenticationService from "../../services/AuthenticationService";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import FormFieldValidation from "../../enums/FormFieldValidation";
import TextField from "../../components/formFields/textField/TextField";

/**
 * @description The reset password form
 */
const ResetPasswordForm = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    const location = useLocation();

    const [password, setPassword] = useState("");
    const [passwordValidation, setPasswordValidation] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);

    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [passwordConfirmationValidation, setPasswordConfirmationValidation] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);

    const [status, setStatus] = useState<Status>(Status.NEUTRAL);

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();

        if (!validate(password, passwordConfirmation, setPasswordValidation, setPasswordConfirmationValidation)) {
            return;
        }

        const email = new URLSearchParams(location.search).get("email")!;
        const token = new URLSearchParams(location.search).get("token");

        submit(email, password, token!, setStatus);
    };

    const onReturnClicked = () => navigate("/login");

    return (
        <Form>

            {
                status !== Status.LOAD_SUCCESS &&
                <>
                    <TextField  label={t("authentication.resetPassword.form.fields.password1.label")}
                                type="password" value={password} onChange={setPassword} validation={passwordValidation}
                                placeholder={t("authentication.resetPassword.form.fields.password1.placeholder")}
                                validationMessage={t("general.validation.invalidPassword")} disabled={status === Status.LOADING} />

                    <TextField  label={t("authentication.resetPassword.form.fields.password2.label")}
                                type="password" value={passwordConfirmation} onChange={setPasswordConfirmation} validation={passwordConfirmationValidation}
                                placeholder={t("authentication.resetPassword.form.fields.password2.placeholder")}
                                validationMessage={t("authentication.resetPassword.form.fields.password2.match")} disabled={status === Status.LOADING} />
                </>
            }

            {
                status === Status.LOAD_ERROR &&
                <div className="alert alert-danger" role="alert">
                    {t("authentication.resetPassword.exceptions.general")}
                </div>
            }

            {
                status === Status.LOAD_SUCCESS &&
                <div className="alert alert-success" role="alert">
                    {t("authentication.resetPassword.complete")}
                </div>
            }

            {
                status !== Status.LOAD_SUCCESS &&
                <Button variant="primary"
                        size="lg"
                        type="submit"
                        className="w-100 mb-3"
                        disabled={status === Status.LOADING}
                        onClick={onSubmit}>
                    {t("general.submit")}
                </Button>
            }

            <Button variant="link"
                    type="button"
                    className="form-text small text-body-secondary p-0"
                    disabled={status === Status.LOADING}
                    onClick={onReturnClicked}>
                {
                    (status !== Status.LOAD_SUCCESS && t("general.cancel")) ||
                    (status === Status.LOAD_SUCCESS && t("authentication.resetPassword.return"))
                }
            </Button>

        </Form>
    );
};

export default function ResetPassword() {
    const { t } = useTranslation();

    return (
        <>
            <h1 className="display-4 text-center mb-3">{t("authentication.resetPassword.title")}</h1>
            <p className="text-body-secondary text-center mb-5">{t("authentication.resetPassword.subtitle")}</p>
            <ResetPasswordForm />
        </>
    );
}

function validate (
    password: string,
    passwordConfirmation: string,
    setPasswordValidation: Dispatch<SetStateAction<FormFieldValidation>>,
    setPasswordConfirmationValidation: Dispatch<SetStateAction<FormFieldValidation>>
) {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.#$^+=!*()@%&]).{8,}$/;
    setPasswordValidation(re.test(password) ? FormFieldValidation.VALID : FormFieldValidation.INVALID);
    setPasswordConfirmationValidation(re.test(passwordConfirmation) && password === passwordConfirmation ? FormFieldValidation.VALID : FormFieldValidation.INVALID);

    return re.test(password) && re.test(passwordConfirmation) && password === passwordConfirmation;
}

function submit (
    email: string,
    password: string,
    token: string,
    setStatus: Dispatch<SetStateAction<Status>>) {
    setStatus(Status.LOADING);

    authenticationService.resetPassword(email, password, token)
        .then(() => setStatus(Status.LOAD_SUCCESS))
        .catch(() => setStatus(Status.LOAD_ERROR));
}