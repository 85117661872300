import ImageResize from 'quill-image-resize-module-react';
import Quill from 'quill';

Quill.register('modules/imageResize', ImageResize);

const toolbarOptions: any[] = [
    [
        { header: [] },
        { font: [] }
    ],
    [
        "bold",
        "italic",
        "underline",
        "strike"
    ],
    [
        { color: [] },
        { background: [] }
    ],
    [
        { list: "bullet" },
        { list: "ordered" },
        { indent: "-1" },
        { indent: "+1" },
    ],
    [
        { align: [] }
    ],
    [
        { script: "sub" },
        { script: "super" }
    ],
    [
        "link",
        "image"
    ],
    [
        "clean"
    ]
];

const modules: any = {
    toolbar: {
        container: toolbarOptions,
        handlers: {},
    },
    clipboard: {
        matchVisual: true,
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }    
};

const formats = [
    "align",
    "background",
    "blockquote",
    "bold",
    "bullet",
    "clean",
    "color",
    "float",
    "font",
    "header",
    "image",
    "indent",
    "italic",
    "link",
    "list",
    "underline",
    "script",
    "strike"
];

export { modules, formats };