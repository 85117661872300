import { Alert, Button, FloatingLabel, Form } from "react-bootstrap";
import Status from "../../../../enums/Status";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import INewMessageFormProps from "./INewMessageFormProps";
import communicationService from "../../../../services/CommunicationService";
import IMessage from "../../../../models/IMessage";
import { NIL } from "uuid";
import FormFieldValidation from "../../../../enums/FormFieldValidation";
import RichTextField from "../../../../components/formFields/richText/RichTextField";

export default function NewMessageForm(props: INewMessageFormProps) {
    const { t } = useTranslation();

    const [subject, setSubject] = useState<string>(props.ticketNumber);
    const [subjectValidation, setSubjectValidation] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);
    
    const [message, setMessage] = useState<string>("");
    const [messageValidation, setMessageValidation] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);
    
    const [status, setStatus] = useState<Status>(Status.NEUTRAL);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const isFormValid = validate(subject, message, setSubjectValidation, setMessageValidation);
        if (isFormValid) {
            postMessage(props.ticketId, subject, message, setStatus, props.onMessageSent);
        }
    };

    const isFormDisabled = status === Status.SAVING || status === Status.SAVE_SUCCESS;

    return (
        <Form onSubmit={onSubmit}>

            <p>
                {t("helpdesk.comms.new")}
            </p>

            <div className="mb-3">

                <FloatingLabel  className="mb-3" controlId="txt-new-msg-title" label={t("helpdesk.comms.subject")}>
                    <Form.Control   type="text" placeholder={t("helpdesk.comms.subject")} value={subject}
                                    isValid={subjectValidation === FormFieldValidation.VALID}
                                    disabled={isFormDisabled} onChange={(e) => setSubject(e.target.value)} />
                </FloatingLabel>
                
            </div>

            <RichTextField  value={message}
                            label={t("helpdesk.comms.message")}
                            onChange={setMessage}
                            height={200}
                            validation={messageValidation}
                            validationMessage={t("helpdesk.comms.invalid")}
                            disabled={isFormDisabled} />

            {
                (
                    (subjectValidation === FormFieldValidation.INVALID || messageValidation === FormFieldValidation.INVALID) &&
                    <Alert variant="danger">{t("helpdesk.comms.invalid")}</Alert>
                ) ||
                (status === Status.SAVING && <Alert variant="info">{t("helpdesk.comms.sending")}</Alert>) ||
                (status === Status.SAVE_SUCCESS && <Alert variant="success">{t("helpdesk.comms.sent")}</Alert>) ||
                (status === Status.SAVE_ERROR && <Alert variant="danger">{t("helpdesk.exceptions.sendMessage")}</Alert>)
            }

            <div className="text-end">
                <Button variant="primary" type="submit" disabled={isFormDisabled}>
                    {t("helpdesk.comms.send")}
                </Button>
            </div>
        </Form>
    );
}

function validate(
    subject: string,
    message: string,
    setSubjectValidation: Dispatch<SetStateAction<FormFieldValidation>>,
    setMessageValidation: Dispatch<SetStateAction<FormFieldValidation>>
) {
    let isValid = true;

    if (subject.trim() === "") {
        setSubjectValidation(FormFieldValidation.INVALID);
        isValid = false;
    } else {
        setSubjectValidation(FormFieldValidation.VALID);
    }

    if (message.trim() === "") {
        setMessageValidation(FormFieldValidation.INVALID);
        isValid = false;
    } else {
        setMessageValidation(FormFieldValidation.VALID);
    }

    return isValid;
}

function postMessage(
    ticketId: number,
    subject: string,
    messageBody: string,
    setStatus: (status: Status) => void,
    onMessageSent: () => void
) {
    setStatus(Status.SAVING);

    const message: IMessage = {
        id: NIL,
        subject: subject,
        body: messageBody,
        ticketId: ticketId,
        date: new Date(),
        recipients: [],
        author: ""
    };

    try {
        communicationService.postMessage(message)
            .then(() => {
                setStatus(Status.SAVE_SUCCESS);
                onMessageSent();
            })
            .catch(() => setStatus(Status.SAVE_ERROR));
    } catch {
        setStatus(Status.SAVE_ERROR);
    }
}