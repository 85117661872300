import { Col, Container, Row } from "react-bootstrap";
import PageHeader from "../../components/pageHeader/PageHeader";
import NewUserForm from "./Form";
import { useTranslation } from "react-i18next";

export default function NewUser() {
    const { t } = useTranslation();

    return (
        <>
            <PageHeader title={t("administration.new")} subtitle={t("administration.titles.hd")} />
            <Container>
                <Row>
                    <Col xs={12} lg={8}>
                        <NewUserForm />
                    </Col>
                </Row>
            </Container>
        </>
    );
}