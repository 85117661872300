import networkService from "./NetworkService";
import IClient from "../models/IClient";


class ClientService {

    /**
     * @description Gets the specified client
     * @param {number} id The ID of the client to be returned
     */
    async getClient(id: number): Promise<IClient> {
        const result = await networkService.get<IClient>(`/client/${id}`);

        if (result.isSuccessful) {
            return result.data as IClient;
        }

        throw result.data;
    }

    /**
     * @description Gets a list of helpdesk clients
     */
    async getClients(): Promise<IClient[]> {
        const result = await networkService.get<IClient[]>("clients");

        if (result.isSuccessful) {
            return result.data as IClient[];
        }

        throw result.data;
    }
    
}

const clientService = new ClientService();
export default clientService;