import { IStyle, mergeStyles } from "@fluentui/merge-styles";
import { Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";

/**
 * @description Pagination component props
 */
interface IPagination {

    /**
     * @description Number of pages
     */
    pages: number;

    /**
     * @description Current page
     */
    activePage: number;

    /**
     * @description Callback to change the page
     * @param {number} page New page number
     */
    onPageChange: (page: number) => void;
}

const disabledStyle: IStyle = { backgroundColor: "var(--bs-pagination-disabled-bg)" };

const PreviousPage = (props: IPagination) => {
    const { t } = useTranslation();

    const disabled = props.activePage === 1;
    const onClick = () => props.onPageChange(props.activePage - 1);

    const baseClassList = ["list-pagination-prev", "pagination-tabs", "card-pagination"];
    const paginationClass = mergeStyles(baseClassList, disabled ? disabledStyle : null);

    return (
        <Pagination className={paginationClass}>
            <Pagination.Prev disabled={disabled} onClick={onClick} className="px-3 border-end">
                <i className="fe fe-arrow-left me-1"></i> {t("pagination.previous.short")}
            </Pagination.Prev>
        </Pagination>
    );
}

const NextPage = (props: IPagination) => {
    const { t } = useTranslation();

    const disabled = props.activePage === props.pages;
    const onClick = () => props.onPageChange(props.activePage + 1);

    const baseClassList = ["list-pagination-next", "pagination-tabs", "card-pagination"];
    const paginationClass = mergeStyles(baseClassList, disabled ? disabledStyle : null);

    return (
        <Pagination className={paginationClass}>
            <Pagination.Prev disabled={disabled} onClick={onClick} className="px-3 border-start">
                <i className="fe fe-arrow-right me-1"></i> {t("pagination.next.short")}
            </Pagination.Prev>
        </Pagination>
    );
}

const PageNumbers = (props: IPagination) => (
    <Pagination className="list-pagination pagination-tabs card-pagination">
        {
            [...Array(props.pages)].map((_, index) => {
                const page = index + 1;
                const active = props.activePage === page;
                const onClick = () => props.onPageChange(page);

                return (
                    <Pagination.Item key={index} active={active} onClick={onClick}>
                        {page}
                    </Pagination.Item>
                )
            })
        }
    </Pagination>
);

const TicketPagination = (props: IPagination) => (
    <>
        <PreviousPage {...props} />
        {props.pages > 1 && <PageNumbers {...props} />}
        <NextPage {...props} />
    </>
);

export default TicketPagination;