import { Route, Routes } from "react-router-dom";
import RedirectToLogin from "./login/RedirectToLogin";
import { v4 as uuid } from "uuid";
import Login from "./login/Login";
import IAuthoriseProps from "./IAuthoriseProps";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import ResetPassword from "./resetPassword/ResetPassword";

const AuthRoutes = (props: IAuthoriseProps) => {
    const loginPaths = ['/', '/login'];
    const forgotPasswordPaths = ['/forgot', '/forgotpassowrd', '/forgot-password'];
    const resetPasswordPaths = ['/reset', '/resetpassowrd', '/reset-password'];
        
    const login = () => <Login login={props.login} onTokenUpdate={props.onTokenUpdate} />;
    const redirectToLogin = () => <RedirectToLogin />;

    const forgotPassword = () => <ForgotPassword />;
    const resetPassword = () => <ResetPassword />;

    return (
        <Routes>
            {loginPaths.map(path => <Route path={path} Component={login} key={uuid()} />)}
            {forgotPasswordPaths.map(path => <Route path={path} Component={forgotPassword} key={uuid()} />)}
            {resetPasswordPaths.map(path => <Route path={path} Component={resetPassword} key={uuid()} />)}
            <Route path="*" Component={redirectToLogin} />
        </Routes>
    );
};

export default AuthRoutes;