import { Nav, NavItem } from "react-bootstrap";
import { Icon } from "../icon/Icon";
import { Link } from "react-router-dom";
import * as icons from 'react-bootstrap-icons';

/**
 * @description Properties for the NavLink component
 */
interface INavLinkProps {

    /**
     * @description Either the link's absolute path, or a relative server path; to use this link as a collapse toggle, precede the path with a hash (#).
     */
    path: string;

    /**
     * @description The text to display in the link
     */
    label: string;

    /**
     * @description The name of the icon to display in the link.
     */
    icon?: keyof typeof icons;

}

/**
 * @description A component that renders a navigation link
 * @param {INavLinkProps} props The properties for the component
 * @returns A React function component
 */
const link = (props: INavLinkProps) => (
    <Nav.Link as={Link} to={props.path}>
        {props.icon && <Icon iconName={props.icon} endMargin={2} />}
        {props.label}
    </Nav.Link>
);

/**
 * @description A component that renders a collapsible navigation link
 * @param {INavLinkProps} props The properties for the component
 * @returns A React function component
 */
const collapseLink = (props: INavLinkProps) => (
    <Nav.Link   as={Link}
                to={props.path}
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls={props.path.replace("#", "")}>
        {props.icon && <Icon iconName={props.icon} endMargin={2} />}
        {props.label}
    </Nav.Link>
);

/**
 * @description A component that renders a navigation link
 * @param {INavLinkProps} props The properties for the component
 * @returns A React function component
 */
export const NavLink = (props: INavLinkProps) => 
    props.path.startsWith("#")
        ? <NavItem children={collapseLink(props)} />
        : <NavItem children={link(props)} />;