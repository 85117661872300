import { Badge, Card, Col, Row } from "react-bootstrap";
import IStatCardProps from "./IStatCardProps";
import { Icon } from "../../icon/Icon";

export default function StatCard(props: IStatCardProps) {
    return (
        <Card className={`shadow ${props.link ? "lift" : ""}`} as="a" href={props.link!}>
            <Card.Body>
                <Row className="align-items-center">
                    <Col>
                        <h6 className="text-uppercase text-body-secondary mb-2">{props.title}</h6>

                        <div className="d-flex align-items-end">
                            <span className="h2 mb-0">{props.value}</span>
                            {
                                props.badge &&
                                <Badge bg={`${props.badge.variant}-subtle`} className="mt-n1 mb-1 ms-2">
                                    {props.badge.text}
                                </Badge>
                            }
                        </div>
                    </Col>

                    {
                        props.icon && 
                        <Col xs="auto">
                            <span className="text-body-secondary mb-0">
                                <Icon iconName={props.icon} />
                            </span>
                        </Col>
                    }
                </Row>
            </Card.Body>
        </Card>
    );
}