import { Alert, Card } from "react-bootstrap";
import Filters from "./Filters";
import TicketPagination from "./Pagination";
import { useState } from "react";
import TicketsTable from "./TicketsTable";
import ITicket from "../../models/ITicket";
import IFilter from "./IFilter";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import IAssignee from "../../models/IAssignee";

export default function TicketsCard(props: { tickets: ITicket[] }) {
    const { t } = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const urlAssignee = queryParams.get('assignee');
    const urlOverdue = queryParams.get('overdue');
    const urlClient = queryParams.get('client');
    
    const [search, setSearch] = useState<string>("");
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const [client] = useState<string>(urlClient || "");
    const [status, setStatus] = useState<string>("");
    const [assignee, setAssignee] = useState<string>(urlAssignee || "");
    const [system, setSystem] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [showOverdue, setShowOverdue] = useState<boolean>(urlOverdue === 'true');

    if (props.tickets.length === 0) {
        return <Alert variant="info">{t("helpdesk.table.noTickets")}</Alert>;
    }

    const filter: IFilter = {
        tickets: props.tickets,
        search: search,
        onSearchChange: setSearch,
        pageSize: pageSize,
        onPageSizeChange: setPageSize,
        status: status,
        statuses: ticketStatuses(props.tickets),
        onStatusChange: setStatus,
        assignee: assignee,
        assignees: ticketAssignees(props.tickets),
        onAssigneeChange: setAssignee,
        system: system,
        systems: ticketSystems(props.tickets),
        onSystemChange: setSystem,
        type: type,
        types: ticketTypes(props.tickets),
        onTypeChange: setType,
        showOverdue: showOverdue, // Include the overdue filter in the filter object
        onShowOverdueChange: setShowOverdue, // Handle changes to the overdue filter
    };

    const now = new Date();

    const tickets = props.tickets
        .filter(ticket => !search.trim() || (ticket.ticketNumber.includes(search) || ticket.title.toLowerCase().includes(search.toLowerCase()))) // Search
        .filter(ticket => !client.trim() || ticket.client.toLowerCase() === client.toLowerCase()) // Client
        .filter(ticket => !status.trim() || ticket.step.name.toLowerCase() === status.toLowerCase()) // Status
        .filter(ticket => (!assignee.trim() || ticket.assignee?.name === assignee)) // Assignee
        .filter(ticket => !system.trim() || ticket.systemOrProduct.toLowerCase() === system.toLowerCase()) // System
        .filter(ticket => !type.trim() || ticket.type.toLowerCase() === type.toLowerCase()) // Type
        .filter(ticket => !showOverdue || (ticket.targetCompletionTime && new Date(ticket.targetCompletionTime) < now));

    const pages = Math.ceil(tickets.length / pageSize);
    
    return (
        <Card>
            <Card.Header>
                <Filters {...filter} />
            </Card.Header>
            <TicketsTable  tickets={tickets} page={page} pageSize={pageSize} />
            {
                pageSize > 0 && pages > 1 &&
                <Card.Footer className="d-flex justify-content-between px-0">
                    <TicketPagination pages={pages} activePage={page} onPageChange={setPage} />
                </Card.Footer>
            }
        </Card>
    );
}

const ticketSystems = (tickets: ITicket[]) => tickets.map(ticket => ticket.systemOrProduct);

const ticketTypes = (tickets: ITicket[]) => tickets.map(ticket => ticket.type);

const ticketStatuses = (tickets: ITicket[]) => tickets.map(ticket => ticket.step.name);

const ticketAssignees = (tickets: ITicket[]) => {
    const allAssignees: IAssignee[] = [];
    
    tickets.forEach(ticket => {
      const assignee = ticket.assignee;
      const clientAssignee = ticket.clientAssignee;
      
      // Add unique `assignee` name if it’s not already in `allAssignees`
      if (assignee && !allAssignees.includes(assignee)) {
        allAssignees.push(assignee);
      }
      
      // Add unique `clientAssignee` name if it’s not already in `allAssignees`
      if (clientAssignee && !allAssignees.includes(clientAssignee)) {
        allAssignees.push(clientAssignee);
      }
    });
  
    return allAssignees; // Return unique names only
  };