import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import ITextFieldProps from "./ITextFieldProps";
import { IsValid, ValidationCssClass } from "./Validation";

export default function TextField(props: ITextFieldProps) {
    const { t } = useTranslation();

    const validationMessage = props.validationMessage || t("general.validation.provideValue");
    
    let validationCssClass = ValidationCssClass(props.validation);
    let isValid = IsValid(props.validation);

    return (
        <Form.Group  className="mb-3" controlId={`txt-${uuid()}`}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control   type={props.type} placeholder={props.placeholder} className={validationCssClass} value={props.value}
                            isValid={isValid} disabled={props.disabled} onChange={(e) => props.onChange(e.target.value)} />
            <Form.Control.Feedback type="invalid">
                {validationMessage}
            </Form.Control.Feedback>
        </Form.Group>
    );
}