import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import Status from "../../enums/Status";
import authenticationService from "../../services/AuthenticationService";
import { useNavigate } from "react-router-dom";
import FormFieldValidation from "../../enums/FormFieldValidation";
import TextField from "../../components/formFields/textField/TextField";

/**
 * @description The forgot password form
 */
const ForgotPasswordForm = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [validation, setValidation] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);
    const [status, setStatus] = useState<Status>(Status.NEUTRAL);

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();

        if (validate(email, setValidation)) {
            submit(email, setStatus);
        }
    };

    const onReturnClicked = () => navigate("/login");

    return (
        <Form>

            {
                status !== Status.LOAD_SUCCESS &&
                <TextField  label={t("authentication.forgotPassword.form.fields.email.label")}
                            type="email" value={email} onChange={setEmail} validation={validation}
                            placeholder={t("authentication.forgotPassword.form.fields.email.placeholder")}
                            validationMessage={t("general.validation.invalidEmail")} disabled={status === Status.LOADING} />
            }

            {
                status === Status.LOAD_ERROR &&
                <div className="alert alert-danger" role="alert">
                    {t("authentication.forgotPassword.exceptions.general")}
                </div>
            }

            {
                status === Status.LOAD_SUCCESS &&
                <div className="alert alert-success" role="alert">
                    {t("authentication.forgotPassword.sent")}
                </div>
            }

            {
                status !== Status.LOAD_SUCCESS &&
                <Button variant="primary"
                        size="lg"
                        type="submit"
                        className="w-100 mb-3"
                        disabled={status === Status.LOADING}
                        onClick={onSubmit}>
                    {t("general.submit")}
                </Button>
            }

            <Button variant="link"
                    type="button"
                    className="form-text small text-body-secondary p-0"
                    disabled={status === Status.LOADING}
                    onClick={onReturnClicked}>
                {t("authentication.forgotPassword.form.return")}
            </Button>

        </Form>
    );
};

export default function ForgotPassword() {
    const { t } = useTranslation();

    return (
        <>
            <h1 className="display-4 text-center mb-3">{t("authentication.forgotPassword.title")}</h1>
            <p className="text-body-secondary text-center mb-5">{t("authentication.forgotPassword.subtitle")}</p>
            <ForgotPasswordForm />
        </>
    );
}

function validate (email: string, setValidation: Dispatch<SetStateAction<FormFieldValidation>>) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email.trim() || !re.test(email.toLowerCase())) {
        setValidation(FormFieldValidation.INVALID_EMAIL);
        return false;
    }

    setValidation(FormFieldValidation.VALID);
    return true;
}

function submit (email: string, setStatus: Dispatch<SetStateAction<Status>>) {
    setStatus(Status.LOADING);

    authenticationService.sendPasswordResetEmail(email)
        .then(() => setStatus(Status.LOAD_SUCCESS))
        .catch(() => setStatus(Status.LOAD_ERROR));
}