import { useTranslation } from "react-i18next";
import { Button, Col, Row } from 'react-bootstrap';
import { Icon } from '../components/icon/Icon';
import Wiki from './Wiki';
import Helpdesk from './Helpdesk';
import Projects from './Projects';
import Header from "./Header";

/**
 * @description Props for the MyTicketsLink component
 */
interface IMyTicketsLinkProps {

    /**
     * @description The number of tickets the user has
     */
    count: number;
}

/**
 * @description A link to the user's tickets
 * @param {number} props Props for the link component
 */
const MyTicketsLink = (props: IMyTicketsLinkProps) => {
    const { t } = useTranslation();

    const text = props.count === 1
        ? t("greeting.myTickets.single")
        : t("greeting.myTickets.plural", { count: props.count });

    return (
        <Button variant="link" className="p-0 text-decoration-none text-danger" href="/helpdesk/tickets/open/my">
            <span className='d-flex align-items-center gap-1'>
                {text}
                <Icon iconName="ChevronRight" />
            </span>
        </Button>
    );
}

export default function Home() {
    const { t } = useTranslation();

    return (
        <>
            <Header />
            { /* <MyTicketsLink count={2} /> */ }

            <Row className='mt-3'>
                <Col xs={12} lg={8}>
                    <Helpdesk />
                    { /* <Projects /> */ }
                </Col>
                {
                    /*
                        <Col xs={12} lg={4}>
                            <h2 className='mb-1'>{t("wiki.titles.kb")}</h2>
                            <p className='text-muted fs-sm'>{t("wiki.titles.recent")}</p>
                            <Wiki />
                        </Col>
                    */
                }
            </Row>
        </>
    );
}