import { Dispatch, SetStateAction, useState } from "react";
import { Alert, Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Status from "../../../../enums/Status";
import { Icon } from "../../../../components/icon/Icon";
import ticketService from "../../../../services/helpdesk/TicketService";

interface INewCommentFormProps {
    ticketId: number;
    onCommentPosted: () => void;
}

export default function NewCommentForm(props: INewCommentFormProps) {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [comment, setComment] = useState<string>("");

    const handlePostComment = () => postComment(props.ticketId, comment, setComment, setStatus, props.onCommentPosted);

    return (
        <Row>
            {
                status === Status.SAVE_ERROR &&
                <Col xs={12}>
                    <Alert variant="danger">{t("helpdesk.exceptions.postComment")}</Alert>
                </Col>
            }

            <Col className="ms-n2">
                <Form className="mt-1">
                    <Form.Label className="visually-hidden">{t("helpdesk.comments.leave")}</Form.Label>
                    <Form.Control   as="textarea"
                                    className="form-control-flush"
                                    data-bs-toggle="autosize"
                                    rows={1}
                                    placeholder={t("helpdesk.comments.leave")}
                                    onChange={(e) => setComment(e.target.value)}
                                    disabled={status === Status.SAVING}
                                    />
                </Form>
            </Col>

            <Col xs="auto" className="align-self-end">
                <div className="text-body-secondary mb-2">
                    {
                        (
                            status === Status.SAVING &&
                            <Spinner animation="border" variant="dark" size="sm" className="me-3" />
                        ) ||
                        <OverlayTrigger placement="top" overlay={<Tooltip>{t("helpdesk.comments.post")}</Tooltip>}>
                            <Button variant="link" className="text-reset me-3" onClick={handlePostComment} disabled={status === Status.SAVING}>
                                <Icon iconName="Send" />
                            </Button>
                        </OverlayTrigger>
                    }
                </div>
            </Col>
        </Row>
    );
}

function postComment(
    ticketId: number,
    comment: string,
    setComment: Dispatch<SetStateAction<string>>,
    setStatus: Dispatch<SetStateAction<Status>>,
    callback: () => void
) {
    setStatus(Status.SAVING);

    try {
        ticketService.postComment(ticketId, comment)
            .then(() => {
                setComment("");
                setStatus(Status.SAVE_SUCCESS);

                callback();
            })
            .catch(() => setStatus(Status.SAVE_ERROR));
    } catch {
        setStatus(Status.SAVE_ERROR);
    }
}