import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Status from "../../enums/Status";
import IStaticValue from "../../models/IStaticValue";
import helpdeskService from "../../services/helpdesk/HelpdeskService";
import ICreateTicket from "../../interfaces/ICreateTicket";
import ticketService from "../../services/helpdesk/TicketService";
import Dropdown from "./fields/Dropdown";
import Range from "./fields/Range";
import TextField from "./fields/TextField";
import AttachmentDropzone from "./fields/attachments/AttachmentDropzone";
import ICreateAttachment from "../../models/ICreateAttachment";
import FormFieldValidation from "../../enums/FormFieldValidation";
import RichTextField from "../../components/formFields/richText/RichTextField";

const slaValueTextColourCssClass = (value: number) => {
    switch (value) {
        case 1: return "text-success";
        case 2: return "text-warning";
        case 3: return "text-danger";
        default: return "text-black";
    }
}

export default function NewTicketForm() {
    const { t } = useTranslation();

    const [skills, setSkills] = useState<IStaticValue[]>([]);
    const [tasks, setTasks] = useState<IStaticValue[]>([]);

    const [status, setStatus] = useState<Status>(Status.NEUTRAL);

    const [title, setTitle] = useState<string>("");
    const [isTitleValid, setIsTitleValid] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);

    const [skillId, setSkillId] = useState<number>(undefined!);
    const [isSkillValid, setIsSkillValid] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);

    const [typeId, setTypeId] = useState<number>(undefined!);
    const [isTypeValid, setIsTypeValid] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);

    const [details, setDetails] = useState<string>("");
    const [isDetailsValid, setIsDetailsValid] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);

    const [severity, setSeverity] = useState<number>(1);
    const [impact, setImpact] = useState<number>(1);

    const [contactName, setContactName] = useState<string>("");
    const [isContactNameValid, setIsContactNameValid] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);

    const [contactEmail, setContactEmail] = useState<string>("");
    const [isContactEmailValid, setIsContactEmailValid] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);

    const [attachments, setAttachments] = useState<ICreateAttachment[]>([]);

    useEffect(() => loadDropdowns(setSkills, setTasks, setStatus), []);

    const isFormDisabled = [Status.LOADING, Status.LOAD_ERROR, Status.SAVING, Status.SAVE_SUCCESS].includes(status);

    const handleSkillChanged = (value: string) => onSkillChanged(value, setSkillId);
    const handleTypeChanged = (value: string) => onTypeChanged(value, setTypeId);
    const handleAttachmentsAdded = (newAttachments: ICreateAttachment[]) => setAttachments([...attachments, ...newAttachments]);

    const onSubmit = () => {
        const validation = validateTicket(title, setIsTitleValid, skillId, setIsSkillValid, typeId, setIsTypeValid, details, setIsDetailsValid, contactName, setIsContactNameValid, contactEmail, setIsContactEmailValid);
        if (validation.every(v => v === FormFieldValidation.VALID)) {
            submitTicket(setStatus, attachments, title, details, contactName, contactEmail, severity, impact, skillId, typeId);
        } else {
            console.log("isTitleValid", isTitleValid);
            console.log("isSkillValid", isSkillValid);
            console.log("isTypeValid", isTypeValid);
            console.log("isDetailsValid", isDetailsValid);
            console.log("isContactNameValid", isContactNameValid);
            console.log("isContactEmailValid", isContactEmailValid);
        }
    };

    const severityValue = t(`helpdesk.sla.values.${severity}`),
            severityTextColour = slaValueTextColourCssClass(severity);
    
    const impactValue = t(`helpdesk.sla.values.${impact}`),
            impactTextColour = slaValueTextColourCssClass(impact);

    const contactEmailValidationMessage = isContactEmailValid === FormFieldValidation.INVALID_EMAIL
        ? t("general.validation.invalidEmail")
        : t("helpdesk.form.validation.contact");

    return (
        <Form>
            <Row>
                <Col>
                    <TextField type="text" label={t("helpdesk.form.fields.title")} value={title} onChange={setTitle} validation={isTitleValid} disabled={isFormDisabled} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    {
                        (status === Status.LOAD_ERROR && <Alert variant="danger">{t("helpdesk.exceptions.loadSkills")}</Alert>) ||
                        <div className="mb-3">
                            <Dropdown   label={t("helpdesk.form.fields.system")} value={skillId} disabled={isFormDisabled}
                                        options={skills.map(s => ({ value: s.id.toString(), label: s.name }))}
                                        validation={isSkillValid} validationMessage={t("general.validation.selectValue")}
                                        onChange={(value) => handleSkillChanged(value)} />
                        </div>
                    }
                </Col>
                <Col xs={12} lg={6}>
                    {
                        (status === Status.LOAD_ERROR && <Alert variant="danger">{t("helpdesk.exceptions.loadTasks")}</Alert>) ||
                        <>
                            <div className="mb-3">
                                <Dropdown   label={t("helpdesk.form.fields.type")} value={typeId} disabled={isFormDisabled}
                                            options={tasks.map(s => ({ value: s.id.toString(), label: s.name }))}
                                            validation={isTypeValid} validationMessage={t("general.validation.selectValue")}
                                            onChange={(value) => handleTypeChanged(value)} />
                                {
                                    (typeId || typeId === 0) &&
                                    <span className="d-block text-muted fs-sm">{tasks.find(t => t.id === typeId)?.description}</span>
                                }
                            </div>
                        </>
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <Range  min={1} max={3} step={1} value={severity} label={t("helpdesk.form.fields.severity")}
                            valueLabel={severityValue} valueLabelColourCssClass={severityTextColour} onChange={setSeverity} />
                </Col>
                <Col xs={12} lg={6}>
                    <Range  min={1} max={3} step={1} value={impact} label={t("helpdesk.form.fields.impact")}
                            valueLabel={impactValue} valueLabelColourCssClass={impactTextColour} onChange={setImpact} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <RichTextField  label={t("helpdesk.form.fields.description")} value={details} onChange={setDetails}
                                    disabled={isFormDisabled} validation={isDetailsValid} validationMessage={t("helpdesk.form.validation.details")} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>{t("helpdesk.form.contact.title")}</h4>
                    <p>{t("helpdesk.form.contact.subtitle")}</p>
                    
                    <TextField  type="text" label={t("helpdesk.form.fields.contact.name")} value={contactName} onChange={setContactName}
                                validation={isContactNameValid} validationMessage={t("helpdesk.form.validation.contact")} disabled={isFormDisabled} />
                    
                    <TextField  type="email" label={t("helpdesk.form.fields.contact.email")} value={contactEmail} onChange={setContactEmail}
                                validation={isContactEmailValid} validationMessage={contactEmailValidationMessage} disabled={isFormDisabled} />
                </Col>
            </Row>
            <Row>
                <AttachmentDropzone attachments={attachments} onAttachmentsAdded={handleAttachmentsAdded} />
            </Row>
            <Row>
                <Col xs={12} lg={{ span: 8, offset: 2 }}>
                    <div className="d-grid gap-2">
                        <Alert variant="info" show={status === Status.SAVING} className="mb-0">
                            {t("helpdesk.form.saving")}
                        </Alert>
                        <Alert variant="success" show={status === Status.SAVE_SUCCESS} className="mb-0">
                            {t("helpdesk.form.saved")}
                        </Alert>
                        <Alert variant="danger" show={status === Status.SAVE_ERROR} className="mb-0">
                            {t("helpdesk.exceptions.createTicket")}
                        </Alert>
                        {
                            status !== Status.SAVE_SUCCESS &&
                            <Button variant="primary" size="lg" disabled={isFormDisabled} onClick={onSubmit}>
                                {t("helpdesk.form.submit")}
                            </Button>
                        }
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

function onSkillChanged (
    value: string,
    setSkillId: Dispatch<SetStateAction<number>>
)
{
    setSkillId(Number(value));
}

function onTypeChanged (
    value: string,
    setTypeId: Dispatch<SetStateAction<number>>
) {
    setTypeId(Number(value));
}

function loadDropdowns (
    setSkills: Dispatch<SetStateAction<IStaticValue[]>>,
    setTasks: Dispatch<SetStateAction<IStaticValue[]>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);
    
    try {
        Promise.all([ helpdeskService.getSkills(), helpdeskService.getTasks() ])
            .then(([skills, tasks]) => {
                setSkills(skills);
                setTasks(tasks);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    } catch {
        setStatus(Status.LOAD_ERROR);
    }
}

function validateTicket (
    title: string,
    setTitleValid: Dispatch<SetStateAction<FormFieldValidation>>,
    skillId: number,
    setSkillValid: Dispatch<SetStateAction<FormFieldValidation>>,
    typeId: number,
    setTypeValid: Dispatch<SetStateAction<FormFieldValidation>>,
    details: string,
    setDetailsValid: Dispatch<SetStateAction<FormFieldValidation>>,
    contactName: string,
    setContactNameValid: Dispatch<SetStateAction<FormFieldValidation>>,
    contactEmail: string,
    setContactEmailValid: Dispatch<SetStateAction<FormFieldValidation>>
) {
    const titleValidation = title.length > 0 ? FormFieldValidation.VALID : FormFieldValidation.INVALID,
            skillValidation = skillId !== undefined ? FormFieldValidation.VALID : FormFieldValidation.INVALID,
            typeValidation = typeId !== undefined ? FormFieldValidation.VALID : FormFieldValidation.INVALID,
            detailsValidation = details.length > 0 ? FormFieldValidation.VALID : FormFieldValidation.INVALID;

    let contactNameValidation = FormFieldValidation.NOT_VALIDATED,
        contactEmailValidation = FormFieldValidation.NOT_VALIDATED;
    

    if (contactName.length === 0 && contactEmail.length === 0) {
        contactNameValidation = FormFieldValidation.VALID;
        contactEmailValidation = FormFieldValidation.VALID;
    } else if (contactName.length > 0 && contactEmail.length > 0) {
        contactNameValidation = FormFieldValidation.VALID;

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        contactEmailValidation = re.test(String(contactEmail).toLowerCase())
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID_EMAIL;
    } else {
        contactNameValidation = contactName.length > 0
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID;
        contactEmailValidation = contactEmail.length > 0
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID;
    }

    setTitleValid(titleValidation);
    setSkillValid(skillValidation);
    setTypeValid(typeValidation);
    setDetailsValid(detailsValidation);
    setContactNameValid(contactNameValidation);
    setContactEmailValid(contactEmailValidation);

    return [titleValidation, skillValidation, typeValidation, detailsValidation, contactNameValidation, contactEmailValidation];
}

function submitTicket (
    setStatus: Dispatch<SetStateAction<Status>>,
    attachments: ICreateAttachment[],
    title: string,
    details: string,
    contactName: string,
    contactEmail: string,
    severity: number,
    impact: number,
    skillId: number,
    typeId: number,
    subtypeId?: number,
    client?: string
) {
    setStatus(Status.SAVING);

    const ticket: ICreateTicket = {
        title,
        details,
        client,
        contactName,
        contactEmail,
        system_or_product: skillId,
        typeId,
        subtypeId,
        severity,
        impact,
        attachments
    };

    try {
        ticketService.createTicket(ticket)
            .then((ticketId) => {
                setStatus(Status.SAVE_SUCCESS);
                setTimeout(() => window.location.href = `/helpdesk/ticket/${ticketId}`, 750);
            })
            .catch(() => setStatus(Status.SAVE_ERROR));
    } catch {
        setStatus(Status.SAVE_ERROR);
    }
}