import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Icon } from "../../components/icon/Icon";
import IExportToExcelButtonProps from "./IExportToExcelButtonProps";
import Status from "../../enums/Status";
import { Dispatch, SetStateAction, useState } from "react";
import ITicket from "../../models/ITicket";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import i18n from 'i18next';

export default function ExportToExcel(props: IExportToExcelButtonProps) {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.NEUTRAL);

    const onClick = () => Export(props.tickets, setStatus);
    
    return (
        <Button variant="light" size="sm" onClick={onClick} disabled={status !== Status.NEUTRAL}>
            <span className="d-flex align-items-center">
                {
                    (status === Status.LOADING && <Spinner size="sm" animation="border" className="me-2" />) ||
                    <Icon iconName="FileSpreadsheet" endMargin={2} />
                }
                <span>{t("general.export.excel")}</span>
            </span>
        </Button>
    );
}

function Export(
    tickets: ITicket[],
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Tickets');

        worksheet.columns = [
            { header: "Ticket Number", key: "ticketNumber" },
            { header: "Title", key: "title" },
            { header: "Created", key: "created" },
            { header: "Primary Contact", key: "contact" },
            { header: "Status", key: "step" },
            { header: "Assigned To", key: "assignee" },
            { header: "System, Skill / Service", key: "systemOrProduct" },
            { header: "Type of Request", key: "type" },
            { header: "Severity", key: "severity" },
            { header: "Impact to Business", key: "businessImpact" },
            { header: "Priority", key: "priority" }
        ];

        worksheet.getRows(1, 1)!.forEach((row: any) => row.font = { bold: true });
        
        tickets.forEach((ticket: ITicket) => {
            worksheet.addRow({
                ticketNumber: ticket.ticketNumber,
                title: ticket.title,
                created: ticket.created,
                contact: ticket.contact.name,
                step: ticket.step.name,
                assignee: ticket.assignee?.name,
                systemOrProduct: ticket.systemOrProduct,
                type: ticket.type,
                severity: ticket.severity,
                businessImpact: ticket.businessImpact,
                priority: ticket.priority.name
            });
        });

        worksheet.columns.forEach((column: any) => {
            let dataMax = 0;
            
            column.eachCell({ includeEmpty: true }, (cell: any) => {
                let columnLength = cell.value ? String(cell.value).length : 10;
                if (columnLength > dataMax) {
                    dataMax = columnLength;
                }
            });

            column.width = dataMax < 10 ? 10 : dataMax;
        });

        workbook.xlsx.writeBuffer()
            .then((buffer: any) => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, 'tickets.xlsx');
            });
    } catch (e) {
        alert(i18n.t("general.export.exception"));
    } finally {
        setStatus(Status.NEUTRAL);
    }
}