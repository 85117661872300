import FormFieldValidation from "../../../enums/FormFieldValidation";

const ValidationCssClass = (validation: FormFieldValidation): string => {
    switch (validation) {
        case FormFieldValidation.VALID:
            return "is-valid";
        case FormFieldValidation.INVALID:
        case FormFieldValidation.INVALID_EMAIL:
            return "is-invalid";
        default:
            return "";
    }
}

const IsValid = (validation: FormFieldValidation): boolean => {
    switch (validation) {
        case FormFieldValidation.VALID:
            return true;
        case FormFieldValidation.INVALID:
        case FormFieldValidation.INVALID_EMAIL:
            return false;
        default:
            return null!;
    }
}

export { ValidationCssClass, IsValid };