/**
 * @description Ticket status flags
 */
enum TicketStatus {

    /**
     * @description Open status flag
     */
    OPEN = "Open",

    /**
     * @description Closed in month status flag
     */
    CLOSED_IN_MONTH = "ClosedInMonth",

    /**
     * @description Closed status flag
     */
    CLOSED = "Closed",

    /**
     * @description Archived status flag
     */
    ARCHIVED = "Archived",

    /**
     * @description On hold status flag
     */
    ON_HOLD = "OnHold"
}

export default TicketStatus;