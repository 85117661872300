import IPageHeaderProps from "./IPageHeaderProps";

const PageHeader = (props: IPageHeaderProps) => (
    <header className="header">
        <div className="header-body">
            {props.subtitle && <h6 className="header-pretitle">{props.subtitle}</h6>}
            <h1 className="header-title">{props.title}</h1>
        </div>
    </header>
);

export default PageHeader;