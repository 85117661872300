import React, { useState } from "react";
import { Button, Form, Alert, Col, OverlayTrigger, Row, Spinner, Tooltip, Container } from "react-bootstrap";
import ITicket from "../../../models/ITicket";
import ticketService from "../../../services/helpdesk/TicketService";
import accountService from "../../../services/AccountService";
import { t } from "i18next";
import { Icon } from "../../../components/icon/Icon";
import Status from "../../../enums/Status";
import { useTranslation } from "react-i18next";


export default function TicketActionTab({ ticket }: { ticket: ITicket }) {
    const { t } = useTranslation();
    
  const [newComment, setNewComment] = useState<string>("");
  const [actionError, setActionError] = useState<string | null>(null);
  const [status, setStatus] = useState<Status>(Status.LOADING);

  const currentUser = accountService.getAccount();

  // Handler for action button click
  const handleActionButtonClick = async () => {
    try {
      if (ticket.clientAssignee && (await currentUser).username.toLowerCase() === ticket.clientAssignee.emailAddress.toLowerCase()) {
        // If the ticket has a client assignee, and user is Admin, release to queue
        await ticketService.releaseToQueue(ticket.id, newComment);
      } else {
        // If the ticket doesn't have a client assignee, assign to the client
        //await ticketService.assignToClient(ticket.id, ticket.client, ticket.clientAssignee.emailAddress, newComment);
      }
      setActionError(null); // Reset error on success
    } catch (error) {
      setActionError("An error occurred while performing the action.");
    }
  };

  // Handler for comment input change
  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewComment(e.target.value);
  };

  return (
    <Container>
    <Row>
      <h3>Ticket Actions</h3>
      
      {/* Error Message */}
      {actionError && (
        <Col xs={12}>
          <Alert variant="danger">{actionError}</Alert>
        </Col>
      )}
  
      {/* Comment Box */}
      <Col xs={12}>
        <Form className="mt-1">
          <Form.Label className="visually-hidden">{t("helpdesk.comments.leave")}</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={newComment}
            onChange={handleCommentChange}
            placeholder={t("helpdesk.comments.leave")}
            className="form-control-flush"
            data-bs-toggle="autosize"
          />
        </Form>
      </Col>
  
      {/* Action Button */}
      <Col xs="auto" className="d-flex justify-content-center">
        <div className="d-flex justify-content-center align-items-center mb-2">
            {
            status === Status.SAVING ? (
                <Spinner animation="border" variant="dark" size="sm" className="me-3" />
            ) : (
                <Button 
                variant="info" 
                onClick={handleActionButtonClick}>
                {ticket.clientAssignee ? "Return to Dolphin" : "Client Reassign"}
                </Button>
            )
            }
        </div>
        </Col>
    </Row>
    </Container>
  );
}