import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IForwardRefProps from "../../interfaces/IForwardRefProps";
import Status from "../../enums/Status";
import accountService from "../../services/AccountService";
import IAccount from "../../models/IAccount";
import { Icon } from "../icon/Icon";

/**
 * @description Properties for the AccountMenu component
 */
interface IAccountMenuProps {
    logout: () => void;
}

/**
 * @description A custom toggle for the AccountMenu dropdown component
 * @param {ReactNode} children The children to render in the toggle
 * @param {MouseEventHandler} onClick The toggle click event handler
 */
const Toggle = React.forwardRef<HTMLButtonElement, IForwardRefProps>(({ children, onClick }, ref) => 
    <button type="button" className="btn" ref={ref} onClick={onClick}>{children}</button>);

export default function AccountMenu(props: IAccountMenuProps) {
    const { t } = useTranslation();

    const [account, setAccount] = React.useState<IAccount>(null!);
    const [status, setStatus] = React.useState<Status>(Status.LOADING);

    useEffect(() => getAccountDetails(setAccount, setStatus), []);

    const initials = account 
        ? account.firstName && account.lastName
            ? `${account.firstName.charAt(0)}${account.lastName.charAt(0)}`
            : account.firstName
                ? account.firstName.charAt(0)
                : account.username.charAt(0)
        : "...";

    return (
        <Dropdown drop="up-centered" title={t("navigation.account.name")}>
            <Dropdown.Toggle as={Toggle} id="sidebarAccount">
            <div className="avatar avatar-sm avatar-online">
                <span className="avatar-title rounded-circle">
                    {
                        (
                            status === Status.LOAD_SUCCESS &&
                            <span>{initials}</span>
                        ) ||
                        <Icon iconName="Person" />
                    }
                </span>
            </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    /*
                        <Dropdown.Item as={Link} to="/profile">
                            <span className="d-block">{t("navigation.account.account")}</span>
                            {
                                status === Status.LOAD_SUCCESS && account &&
                                <span className="d-block fs-sm text-muted">
                                    {account.firstName} {account.lastName}
                                </span>
                            }
                        </Dropdown.Item>
                    */
                }
                {
                    status === Status.LOAD_SUCCESS && account &&
                    <Dropdown.Item>
                        <span className="text-dark">
                            {account.firstName} {account.lastName}
                        </span>
                    </Dropdown.Item>
                }
                <Dropdown.Divider />
                <Dropdown.Item onClick={props.logout}>{t("navigation.account.logout")}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

function getAccountDetails(
    setAccount: Dispatch<SetStateAction<IAccount>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    try {
        accountService.getAccount()
            .then((account) => {
                setStatus(Status.LOAD_SUCCESS);
                setAccount(account);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    } catch {
        setStatus(Status.LOAD_ERROR);
    }
}