import { useLocation } from "react-router-dom";
import IRouteComponent from "../interfaces/IRouteComponent";

const components: IRouteComponent = {
    "/tasks/open": <h1>Open</h1>,
    "/tasks/complete": <h1>Closed</h1>,
    "/tasks/complete/month": <h1>Closed in month</h1>,
};

export default function Tasks() {
    const location = useLocation();
    return components[location.pathname];
}