import { Col, ListGroup, ListGroupItem, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from "./AttachmentDropzone.module.scss";
import ICreateAttachment from "../../../../models/ICreateAttachment";
import { useTranslation } from "react-i18next";
import { Icon } from "../../../../components/icon/Icon";
import { useDropzone } from "react-dropzone";
import { Dispatch, SetStateAction, useCallback, useState } from "react";

interface INewAttachmentFormProps {
    attachments: ICreateAttachment[];
    onAttachmentsAdded: (attachments: ICreateAttachment[]) => void;
}

const Attachment = (props: ICreateAttachment) => {
    const { t } = useTranslation();

    let size: string;

    if (props.content.length < 1_024 || props.content.length > 1_073_741_824) {
        size = `${props.content.length} Bytes`;
    } else if (props.content.length < 1_048_576) {
        size = `${(props.content.length / 1024).toFixed(2)} KB`;
    } else {
        size = `${(props.content.length / 1_048_576).toFixed(2)} MB`;
    }

    return (
        <ListGroupItem>
            <Row className="align-items-center">
                <Col>
                    <h4 className="mb-1 name">
                        {props.name}
                    </h4>
                    <p className="card-text small text-body-secondary mb-1">
                        {size}
                    </p>
                </Col>
                <Col xs="auto">
                    <OverlayTrigger placement="left" overlay={<Tooltip>{t("general.remove")}</Tooltip>}>
                        <span className="text-danger">
                            <Icon iconName="TrashFill" />
                        </span>
                    </OverlayTrigger>
                </Col>
            </Row>
        </ListGroupItem>
    );
};

export default function NewAttachmentForm(props: INewAttachmentFormProps) {
    const { t } = useTranslation();

    const [disabled, setDisabled] = useState(false);

    const handleDrop = (files: File[]) => parseFiles(files, setDisabled, props.onAttachmentsAdded);
    const onDrop = useCallback(handleDrop, [props.onAttachmentsAdded]);
      
    const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop});

    const dragStatusTextKey = isDragActive ? "dragActive" : "dragInactive";
    const text = t(`helpdesk.attachments.dropzone.${dragStatusTextKey}`);

    return (
        <div className="d-block mb-3">
            <form className={`${styles.dropzone} ${isDragActive ? styles.dragActive : ""}`}>
                <div {...getRootProps()}>
                    <input {...getInputProps()} disabled={disabled} />
                    <p className="mb-0">{text}</p>
                </div>
            </form>

            <ListGroup variant='flush'>
                {props.attachments.map((attachment, index) => <Attachment key={index} name={attachment.name} content={attachment.content} />)}
            </ListGroup>
        </div>
    );
}

async function parseFiles(
    files: File[],
    setDisabled: Dispatch<SetStateAction<boolean>>,
    onAfterParsed: (attachments: ICreateAttachment[]) => void
) {
    setDisabled(true);

    const promises = files.map(async (f) => {
        const content = await getBase64(f);
        return { name: f.name, content };
    });

    const attachments: ICreateAttachment[] = await Promise.all(promises);

    onAfterParsed(attachments);

    setDisabled(false);
}

const getBase64 = (file: File) : Promise<string> => 
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result!.toString());
        reader.onerror = error => reject(error);
    });