import { Dispatch, SetStateAction, useEffect, useState } from "react";
import FormFieldValidation from "../../enums/FormFieldValidation";
import ICreateUser from "../../interfaces/ICreateUser";
import clientService from "../../services/ClientService";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import Dropdown from "./fields/Dropdown";
import TextField from "../../helpdesk/newTicket/fields/TextField";
import Status from "../../enums/Status";
import { useTranslation } from "react-i18next";
import accountService from "../../services/AccountService";

export default function NewUserForm() {
    const { t } = useTranslation();
    
    const [username, setUsername] = useState<string>("");
    const [isUsernameValid, setIsUsernameValid] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);
    const [firstName, setFirstName] = useState<string>("");
    const [isFirstNameValid, setIsFirstNameValid] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);
    const [lastName, setLastName] = useState<string>("");
    const [isLastNameValid, setIsLastNameValid] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);
    const [clientId, setClientId] = useState<number | undefined>(undefined);
    const [isClientIdValid, setIsClientIdValid] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);
    const [isAdministrator, setIsAdministrator] = useState<boolean>(false);
    const [clients, setClients] = useState<{ id: number; name: string }[]>([]);
    const [status, setStatus] = useState<Status>(Status.NEUTRAL);
    const [statusMessage, setStatusMessage] = useState<string>("");

    const isFormDisabled = [
        Status.LOADING,
        Status.LOAD_ERROR,
        Status.SAVING,
        Status.SAVE_SUCCESS
    ].includes(status);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const clientsData = await getClients();
                setClients(clientsData);
            } catch (error) {
                console.error("Failed to fetch clients", error);
                setStatus(Status.LOAD_ERROR);
            }
        };

        fetchClients();
    }, []);

    const validateFields = () => {
        let valid = true;

        // Validate username (e.g., email format)
        const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        if (!validateEmail(username)) {
            setIsUsernameValid(FormFieldValidation.INVALID);
            valid = false;
        } else {
            setIsUsernameValid(FormFieldValidation.VALID);
        }

        // Validate first name
        if (!firstName.trim()) {
            setIsFirstNameValid(FormFieldValidation.INVALID);
            valid = false;
        } else {
            setIsFirstNameValid(FormFieldValidation.VALID);
        }

        // Validate last name
        if (!lastName.trim()) {
            setIsLastNameValid(FormFieldValidation.INVALID);
            valid = false;
        } else {
            setIsLastNameValid(FormFieldValidation.VALID);
        }

        // Validate client ID (if the user is not an admin)
        if (!isAdministrator && !clientId) {
            setIsClientIdValid(FormFieldValidation.INVALID);
            valid = false;
        } else {
            setIsClientIdValid(FormFieldValidation.VALID);
        }

        return valid;
    };

    const onSubmit = () => {
        if (validateFields()) {
            const userData: ICreateUser = {
                username,
                firstName,
                lastName,
                clientId,
                isAdministrator
            };

            submitUser(setStatus, setStatusMessage, userData);
        }
    };

    const handleAdminChange = (isAdmin: boolean) => {
        setIsAdministrator(isAdmin);
        if (isAdmin) {
            // If the user is an admin, set clientId to 0
            setClientId(0);
        } else {
            // If the user is not an admin, reset clientId to undefined
            setClientId(undefined);
        }
    };

    return (
        <Form>
            <Row>
                <Col xs={12} lg={12}>
                    <TextField
                        type="text"
                        label={t("administration.form.fields.username")}
                        value={username}
                        onChange={setUsername}
                        validation={isUsernameValid}
                        validationMessage="Valid email is required"
                        disabled={false}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={12} lg={6}>
                    <TextField
                        type="text"
                        label={t("administration.form.fields.firstName")}
                        value={firstName}
                        onChange={setFirstName}
                        validation={isFirstNameValid}
                        validationMessage="First name is required"
                        disabled={false}
                    />
                </Col>
                <Col xs={12} lg={6}>
                    <TextField
                        type="text"
                        label={t("administration.form.fields.lastName")}
                        value={lastName}
                        onChange={setLastName}
                        validation={isLastNameValid}
                        validationMessage="Last name is required"
                        disabled={false}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={12} lg={12}>
                    <Form.Group controlId="chk-tc-accepted" className="mb-3">
                        <Form.Check
                            type="checkbox"
                            checked={isAdministrator}
                            label={t("administration.form.fields.isAdministrator")}
                            onChange={(e) => handleAdminChange(e.target.checked)}
                        />
                    </Form.Group>
                </Col>

                <Col xs={12} lg={12}>
                    {!isAdministrator ? (
                        <Dropdown
                            label="Client"
                            options={clients.map(client => ({
                                value: client.id,
                                label: client.name
                            }))}
                            value={clientId}
                            onChange={setClientId}
                            validation={isClientIdValid}
                            validationMessage="Client selection is required"
                            disabled={false}
                        />
                    ) : (
                        <Alert variant="info">
                            {t("administration.form.info")}
                        </Alert>
                    )}
                </Col>
            </Row>

            <Row>
                <Col xs={12} lg={{ span: 8, offset: 2 }} className="mt-5">
                    <div className="d-grid gap-2">
                        <Alert variant="info" show={status === Status.SAVING} className="mb-0">
                            {t("administration.form.saving")}
                        </Alert>
                        <Alert variant="success" show={status === Status.SAVE_SUCCESS} className="mb-0">
                            {t("administration.form.saved")}
                        </Alert>
                        <Alert variant="danger" show={status === Status.SAVE_ERROR} className="mb-0">
                            {statusMessage || t("administration.exceptions.createUser")}
                        </Alert>

                        <Button
                            variant="primary"
                            size="lg"
                            disabled={isFormDisabled}
                            onClick={onSubmit}
                        >
                            {t("administration.form.submit")}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

async function getClients() {
    return await clientService.getClients();
}

function submitUser(
    setStatus: Dispatch<SetStateAction<Status>>,
    setStatusMessage: Dispatch<SetStateAction<string>>,
    userData: ICreateUser
) {
    setStatus(Status.SAVING);

    accountService.createUser(userData)
        .then(() => {
            setStatus(Status.SAVE_SUCCESS);
            setStatusMessage("");
            window.location.href = "/administration/users"; 
        })
        .catch((error) => {
            setStatusMessage(error.data);
            setStatus(Status.SAVE_ERROR);
        });
}