/**
 * @description Properties for the collapse component
 */
export default interface ICollapseProps {

    /**
     * @description The ID of the collapse element
     */
    id: string;

    /**
     * @description Child elements to render inside the collapse component
     */
    children: React.ReactNode;
}

/**
 * @description A component that renders a collapsible navigation menu
 * @param {ICollapseProps} props The properties for the component
 * @returns A React function component
 */
export const Collapse = (props: ICollapseProps) => (
    <div className="collapse" id={props.id}>
        <div className="nav nav-sm flex-column">
            {props.children}
        </div>
    </div>
);